import React, { useState, useEffect } from 'react';
import UserGroupContext from '../../contexts/UserGroupContext';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext';
import api from '../../config/axiosConfig';
import {
  Card, CardContent, Container, TextField, Typography, Collapse, Box, IconButton, CircularProgress, InputAdornment,
  Chip, Autocomplete, Tooltip, Switch, Link, tooltipClasses
} from '@mui/material';
import { DoDisturbOn, ExpandMore, ExpandLess, SaveAs, DeleteSweep, EmojiTransportation, TimeToLeave, DriveFileRenameOutline, FilterList } from '@mui/icons-material';
import styles from './css/OrderTable.module.css';
import { ReactComponent as NoDataImg } from '../../pages/img/NoData.svg';
import { ReactComponent as NoFilterImg } from '../../pages/img/NoFilter.svg';

function deepCompare(oldObj, newObj, result = {}) {
  Object.keys({ ...oldObj, ...newObj }).forEach(key => {
      const oldVal = oldObj[key];
      const newVal = newObj[key];

      if (Array.isArray(oldVal) && Array.isArray(newVal)) {
          const arrayChanges = [];
          const maxLength = Math.max(oldVal.length, newVal.length);
          for (let i = 0; i < maxLength; i++) {
              if (i >= oldVal.length) {
                  arrayChanges.push(newVal[i]);
              } else if (i >= newVal.length) {
              } else {
                  const itemChanges = {};
                  deepCompare(oldVal[i], newVal[i], itemChanges);
                  if (Object.keys(itemChanges).length > 0) {
                      if (newVal[i].id !== undefined) {
                          itemChanges['id'] = newVal[i].id;
                      }
                      arrayChanges.push(itemChanges);
                  }
              }
          }
          if (arrayChanges.length > 0) {
              result[key] = arrayChanges;
          }
      } else if (oldVal && typeof oldVal === 'object' && newVal && typeof newVal === 'object') {
          const nestedChanges = {};
          deepCompare(oldVal, newVal, nestedChanges);
          if (Object.keys(nestedChanges).length > 0) {
              result[key] = nestedChanges;
          }
      } else if (oldVal !== newVal) {
          result[key] = newVal;
      }
  });

  return result;
}

function OrderTable(props) {
  const userGroups = React.useContext(UserGroupContext);
  const [orders, setOrders] = useState([]);
  const [errors, setErrors] = useState({});
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [searchingGroupNumber, setSearchingGroupNumber] = useState('');
  const [searchingOrderStatus, setSearchingOrderStatus] = useState([]);
  const [searchingSalesRep, setSearchingSalesRep] = useState([]);
  const [searchingCharterCompany, setSearchingCharterCompany] = useState([]);
  const [searchingVehicleUsage, setSearchingVehicleUsage] = useState('');
  const [searchingArea, setSearchingArea] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [editingOrder, setEditingOrder] = useState(null);
  const [editedOrder, setEditedOrder] = useState({});
  const [openRow, setOpenRow] = useState(null);
  const [loading, setLoading] = useState(null);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [abortController, setAbortController] = useState(null);
  const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
  const years = Array.from({ length: new Date().getFullYear() - 2022 + 1 }, (_, i) => 2023 + i);
  const [tableWidth, setTableWidth] = useState(0);
  
  useEffect(() => {

    const updateDateFromHash = () => {
      const hash = window.location.hash;

      if (hash && /^#\d{4}-\d{2}$/.test(hash)) {
        const [newYear, newMonth] = hash.substring(1).split('-');
        setYear(parseInt(newYear, 10));
        setMonth(parseInt(newMonth, 10));
      }
    };

    const currentDate = new Date();
    if (!window.location.hash || !/^#\d{4}-\d{2}$/.test(window.location.hash)) {
      const newYear = currentDate.getFullYear();
      const newMonth = currentDate.getMonth() + 1;
      setYear(newYear);
      setMonth(newMonth);
      window.location.hash = `#${newYear}-${String(newMonth).padStart(2, '0')}`;
    } else {
      updateDateFromHash();
    }

    setHasUnsavedChanges(false);

    const container = document.getElementById('table-cardcontent');
    if (container) {
      // console.log('監聽視窗大小變化');
      const resizeObserver = new ResizeObserver(() => {
        const width = container.getBoundingClientRect().width;
        if (container.scrollHeight > container.clientHeight) {
          setTableWidth(width - 30 - 17);
          // console.log('有垂直滾動條，寬度減去滾動條寬度:', width - 17);
        } else {
          setTableWidth(width - 30);
          // console.log('無垂直滾動條，寬度不變:', width);
        }
      });
      resizeObserver.observe(container);

      return () => {
        resizeObserver.disconnect();
      };
    }

    window.addEventListener('hashchange', updateDateFromHash);

    return () => {
      window.removeEventListener('hashchange', updateDateFromHash);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    if (year && month >= 1) {
      if (abortController) {
        abortController.abort();
      }
      window.location.hash = `#${year}-${String(month).padStart(2, '0')}`;
      document.title = `日本線業務報表 - ${year}-${String(month).padStart(2, '0')}`;
      setLoading(true);
      setOpenRow(null);
      setOrders([]);
      handleClearFilters();
      fetchOrders(year, month);
    }
  // eslint-disable-next-line
  }, [year, month]);

  useEffect(() => {
    let result = [...orders];

    if (searchingGroupNumber) {
      result = result.filter(order => order.group_number.includes(searchingGroupNumber));
    }

    if (searchingOrderStatus.length > 0) {
      result = result.filter(order =>
        searchingOrderStatus.some(status =>
          order.order_status.includes(status)
        )
      );
    }

    if (searchingSalesRep.length > 0) {
      result = result.filter(order =>
        searchingSalesRep.some(salesRep =>
          order.sales_rep.profile?.nickname.includes(salesRep) || order.sales_rep.username.includes(salesRep)
        )
      );
    }

    if (searchingCharterCompany.length > 0) {
      result = result.filter(order =>
        searchingCharterCompany.some(charterCompany =>
          order.charter_company.company_name.includes(charterCompany)
        )
      );
    }

    if (searchingVehicleUsage) {
      result = result.filter(order => 
        order.itineraries.some(itinerary =>
          itinerary.itinerary_date === searchingVehicleUsage && order.order_status !== '取消'
        )
      );
    }

    if (searchingArea.length > 0) {
      result = result.filter(order =>
        searchingArea.some(area =>
          order.area.includes(area)
        )
      );
    }

    setFilteredOrders(result);
  }, [orders, searchingGroupNumber, searchingOrderStatus, searchingSalesRep, searchingCharterCompany, searchingVehicleUsage, searchingArea]);

  const fetchOrders = async (year, month) => {
    const controller = new AbortController();
    setAbortController(controller);

    setOrders([]);
    setLoading(true);
    try {
      const orderResponse = await api.get(`/japan/api/Order/?from=${'OrderTable'}&year=${year}&month=${month}`, { signal: controller.signal });
      if (!controller.signal.aborted) {
        setOrders(orderResponse.data);
        setFilteredOrders(orderResponse.data);
        setLoading(false);
      }
    } catch (error) {
      if (error.name === 'CanceledError') {
        console.log('終止獲取', year, '年', month, '月的訂單');
      } else {
        console.error('獲取訂單時發生錯誤:', error);
      }
    }
  };

  const handleClearFilters = () => {
    setSearchingGroupNumber('');
    setSearchingOrderStatus([]);
    setSearchingSalesRep([]);
    setSearchingCharterCompany([]);
    setSearchingVehicleUsage('');
    setSearchingArea([]);
  };

  const handleRowClick = (uuid) => {
    if (openRow === uuid) {
      setOpenRow(null);
    } else {
      setOpenRow(uuid);
    }
  };

  const handleEditClick = (order) => {
    setEditingOrder(order.uuid);
    setEditedOrder(order);
    setOpenRow(order.uuid);
    setHasUnsavedChanges(true);

    const element = document.getElementById(order.uuid);
    if (!element) return;

    const offsetTop = element.offsetTop;
    const scrollContainer = document.getElementById('table-cardcontent');
    const scrollPosition = offsetTop - 43.5;

    scrollContainer.scrollTo({
      top: scrollPosition,
      behavior: 'smooth'
    });
  };

  const handleInputChange = (field, value) => {
    if (field.includes('.')) {
      const [objectName, propertyName] = field.split('.');
  
      setEditedOrder(prevState => ({
        ...prevState,
        [objectName]: {
          ...prevState[objectName],
          [propertyName]: value,
        },
      }));
    } else {
      setEditedOrder({
        ...editedOrder,
        [field]: value,
      });
    }
  };

  const handleCancelClick = () => {
    setEditingOrder(null);
    setEditedOrder({});
    setErrors({});
    setHasUnsavedChanges(false);
  };

  const handleSaveClick = async () => {
    const now = Date.now();
    if (now - lastClickTime < 3000) {
      console.log('請勿連續點擊保存按鈕');
      return;
    }

    setLastClickTime(now);

    try {
      const changes ={}
      deepCompare(orders.find(order => order.uuid === editingOrder), editedOrder, changes);
      // console.log('變更:', changes);

      const changedOrderData = {
        ...changes,
        uuid: editingOrder,
        ...(changes.charter_company && { charter_company_id: changes.charter_company.id}),
        ...(changes.vehicle_type && { vehicle_type_id: changes.vehicle_type.id }),
        ...(changes.warning_type && { warning_type_id: changes.warning_type.id }),
        ...(changes.policy_type && { policy_type_id: changes.policy_type.id }),
        ...(changes.remittance_info && { remittance_info_id: changes.remittance_info.id }),
        ...(changes.sales_rep && { sales_rep_id: changes.sales_rep.id }),
      };

      if (Object.keys(changedOrderData).length === 1) {
        setEditingOrder(null);
        setEditedOrder({});
        setErrors({});
        setHasUnsavedChanges(false);
        return;
      }

      const response = await api.patch(`/japan/api/Order/${editingOrder}/`, changedOrderData);
      
      if (response.status === 200) {
        setOrders(orders.map(order => (order.uuid === editingOrder ? response.data : order)));
        setEditingOrder(null);
        setEditedOrder({});
        setErrors({});
        setHasUnsavedChanges(false);
      } else {
        console.error('Failed to save changes:', response);
        setErrors({ general: '保存失敗，請重試。' });
      }
    } catch (error) {
      console.error('Error saving changes:', error);
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      } else {
        setErrors({ general: '發生未知錯誤，請重試或聯繫相關人員。' });
      }
    }
  };

  return (
    <Container maxWidth={false}>
      
      <Box className={styles['tips-box']}>

        <Typography className={styles['page-title']}>
          日本線業務報表
        </Typography>

        <Box className={styles['tips-row']}>
          <Chip
            label={'訂單: ' + filteredOrders.length}
            variant='outlined'
            sx={{
              '& .MuiChip-label': {
                fontSize: '13px',
                fontWeight: 'bold',
                lineHeight: '20px',
                padding: '0 9px',
              }
            }}
          />

          <Chip
            label={'取消: ' + filteredOrders.filter(order => order.order_status === '取消').length}
            variant='outlined'
            className={styles['tips-cancelled']}
            sx={{
              '& .MuiChip-label': {
                padding: '0 9px',
              }
            }}
          />

          <Chip
            label={'未付款: ' + filteredOrders.filter(order => order.order_status === '未付款').length}
            variant='outlined'
            className={styles['tips-unpaid']}
            sx={{
              '& .MuiChip-label': {
                padding: '0 9px',
              }
            }}
          />

          <Chip
            label={'作業金已付: ' + filteredOrders.filter(order => order.order_status === '作業金已付').length}
            variant='outlined'
            className={styles['tips-deposit-paid']}
            sx={{
              '& .MuiChip-label': {
                padding: '0 9px',
              }
            }}
          />

          <Chip
            label={'尾款已付: ' + filteredOrders.filter(order => order.order_status === '尾款已付').length}
            variant='outlined'
            className={styles['tips-balance-paid']}
            sx={{
              '& .MuiChip-label': {
                padding: '0 9px',
              }
            }}
          />

          <Chip
            label={'全額付款: ' + filteredOrders.filter(order => order.order_status === '全額付款').length}
            variant='outlined'
            className={styles['tips-full-paid']}
            sx={{
              '& .MuiChip-label': {
                padding: '0 9px',
              }
            }}
          />

          <Chip
            icon={<TimeToLeave sx={{ fontSize: '20px' }} />}
            label={'接送用車: ' + filteredOrders.filter(order => order.order_status !== '取消').reduce((total, order) => total + order.itineraries.filter(itinerary => itinerary.service_info === '接送').length, 0)}
            variant='outlined'
            className={styles['tips-reception']}
            sx={{
              '& .MuiChip-label': {
                fontSize: '13px',
                fontWeight: 'bold',
                lineHeight: '20px',
                padding: '0 9px',
              }
            }}
          />

          <Chip
            icon={<EmojiTransportation sx={{ fontSize: '20px' }} />}
            label={'全日包車: ' + filteredOrders.filter(order => order.order_status !== '取消').reduce((total, order) => total + order.itineraries.filter(itinerary => itinerary.service_info === '全日包車').length, 0)}
            variant='outlined'
            className={styles['tips-charter']}
            sx={{
              '& .MuiChip-label': {
                fontSize: '13px',
                fontWeight: 'bold',
                lineHeight: '20px',
                padding: '0 9px',
              }
            }}
          />

        </Box>
        
        <Chip
          icon={<FilterList sx={{ fontSize: '20px' }} />}
          label={(
            searchingGroupNumber || searchingOrderStatus.length > 0 || searchingSalesRep.length > 0 || searchingCharterCompany.length > 0 || searchingVehicleUsage || searchingArea.length > 0 ?
              '篩選過濾' +
              [
                searchingGroupNumber ? '團號: ' + searchingGroupNumber : '',
                searchingOrderStatus.length > 0 ? '狀態: ' + searchingOrderStatus.join('、') : '',
                searchingSalesRep.length > 0 ? '承辦業務: ' + searchingSalesRep.join('、') : '',
                searchingCharterCompany.length > 0 ? '包車公司: ' + searchingCharterCompany.join('、') : '',
                searchingVehicleUsage ? '用車日期: ' + searchingVehicleUsage : '',
                searchingArea.length > 0 ? '地區: ' + searchingArea.join('、') : ''
              ].filter(Boolean).join(' / ')
              : '篩選過濾: 無'
          )}
          variant="outlined"
          className={styles['tips-filter']}
          onDelete={
            (searchingGroupNumber || searchingOrderStatus.length > 0 || searchingSalesRep.length > 0 || searchingCharterCompany.length > 0 || searchingVehicleUsage || searchingArea.length > 0) && !editingOrder
              ? handleClearFilters
              : null
          }
          deleteIcon={
            <Tooltip title="點擊清除篩選條件" placement="right">
              <DeleteSweep />
            </Tooltip>
          }
        />

      </Box>

      <Box className={styles['choose-box']}>

        <Box className={styles['choose-row']} sx={{ padding: '0 100px' }}>

          <Box className={styles['hang-design']} />
          <Box className={styles['hang-design']} />

        </Box>

        <Box className={styles['choose-row']} sx={{ padding: '0 74.5px' }}>
          <Autocomplete
            id="year-select"
            options={years}
            getOptionLabel={(option) => String(option)}
            openText='展開'
            value={year}
            loading={loading}
            loadingText='載入中...'
            disableClearable
            readOnly={editingOrder}
            renderInput={(params) =>
              <TextField {...params}
                helperText='請選擇年份'
                size='small'
                variant="outlined"
              />
            }
            sx={{
              width: '100px',
              '& .MuiAutocomplete-input': {
                fontSize: '15px',
                fontWeight: 'bold',
              },
            }}
            onChange={(e, value) => setYear(value || null)}
          />

          <Typography className={styles['tips-text']}>
            上方數據皆為篩選後的訂單進行統計，用車統計已過濾取消訂單。
          </Typography>

          <Autocomplete
            id="month-select"
            options={Array.from({ length: 12 }, (_, i) => i + 1)}
            getOptionLabel={(option) => String(option)}
            openText='展開'
            value={month}
            loading={loading}
            loadingText='載入中...'
            disableClearable
            readOnly={editingOrder}
            renderInput={(params) =>
              <TextField {...params}
                helperText='請選擇月份'
                size='small'
                variant="outlined"
              />
            }
            sx={{
              width: '100px',
              '& .MuiAutocomplete-input': {
                fontSize: '15px',
                fontWeight: 'bold',
              },
            }}
            onChange={(e, value) => setMonth(value || null)}
          />
        </Box>
      </Box>

      <Box className={styles['function-box']}>

        <Autocomplete
          className={styles['functions']}
          id="group-number-search"
          options={orders.map(order => order.group_number)}
          noOptionsText={<NoFilterImg style={{ width: '100%', height: '100%' }} />}
          openText='展開'
          loading={loading}
          loadingText='載入中...'
          readOnly={editingOrder}
          renderInput={(params) => 
            <TextField {...params}
              label='團號'
              size='small'
              variant="standard"
            />
          }
          sx={{
            '& .MuiAutocomplete-input': {
              fontSize: '15px',
              fontWeight: 'bold',
            },
          }}
          onChange={(e, value) => setSearchingGroupNumber(value || '')}
        />

        <Autocomplete
          className={styles['functions']}
          id="order-status-search"
          // options={['未付款', '作業金已付', '尾款已付', '全額付款', '取消']}
          options={orders.reduce((unique, order) => {
            return unique.includes(order.order_status) ? unique : [...unique, order.order_status];
          }, [])}
          noOptionsText={<NoFilterImg style={{ width: '100%', height: '100%' }} />}
          openText='展開'
          value={searchingOrderStatus}
          loading={loading}
          loadingText='載入中...'
          disableClearable
          readOnly={editingOrder}
          multiple
          limitTags={1}
          renderInput={(params) => (
            <TextField
              {...params}
              label="狀態"
              size="small"
              variant="standard"
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              let color;
              let backgroundColor;
              switch (option) {
                case '未付款':
                  color = '4f4d4d';
                  backgroundColor = '#b8b5b5';
                  break;
                case '作業金已付':
                  color = '#305123';
                  backgroundColor = '#94cf73';
                  break;
                case '尾款已付':
                  color = '#703a1e';
                  backgroundColor = '#e8ab65';
                  break;
                case '全額付款':
                  color = '243484';
                  backgroundColor = '#5e91f0';
                  break;
                case '取消':
                  color = '#702d2c';
                  backgroundColor = '#e38785';
                  break;
                default:
                  color = 'default';
                  backgroundColor = 'default';
              }
              
              return (
                <Chip
                  key={option}
                  label={option}
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor: backgroundColor,
                    color: color,
                  }}
                />
              );
            })
          }
          sx={{
            maxWidth: '190px',
            '& .MuiAutocomplete-input': {
              padding: '8px 0',
            },
            '& .MuiAutocomplete-tag': {
              margin: '0 3px 1px 3px',
              fontSize: '13px',
              fontWeight: 'bold',
            },
            '& .MuiChip-root': {
              height: '24px',
              margin: '0 3px 2px 0',
            },
            '& .MuiChip-label': {
              fontWeight: 'bold',
              padding: editingOrder ? '0 6px 0 6px' : '0 0 0 6px',
              height: '24px',
            },
            '& .MuiChip-root .MuiChip-deleteIcon': {
              margin: '0 2px 0 0',
            },
          }}
          onChange={(e, value) => setSearchingOrderStatus(value || '')}
        />

        <Autocomplete
          className={styles['functions']}
          id="sales-rep-search"
          options={orders.reduce((unique, order) => {
            return unique.includes(order.sales_rep.profile?.nickname || order.sales_rep.username) ? unique : [...unique, order.sales_rep.profile?.nickname || order.sales_rep.username];
          }, [])}
          noOptionsText={<NoFilterImg style={{ width: '100%', height: '100%' }} />}
          openText='展開'
          value={searchingSalesRep}
          loading={loading}
          loadingText='載入中...'
          disableClearable
          readOnly={editingOrder}
          multiple
          limitTags={1}
          renderInput={(params) =>
            <TextField {...params}
              label='承辦業務'
              size='small'
              variant="standard"
            />
          }
          sx={{
            maxWidth: '270px',
            '& .MuiAutocomplete-input': {
              padding: '8px 0',
            },
            '& .MuiAutocomplete-tag': {
              margin: '0 3px 1px 3px',
              fontSize: '13px',
              fontWeight: 'bold',
            },
            '& .MuiChip-root': {
              height: '24px',
              margin: '0 3px 2px 0',
              backgroundColor: '#ebf1fd',
            },
            '& .MuiChip-label': {
              fontWeight: 'bold',
              padding: editingOrder ? '0 6px 0 6px' : '0 0 0 6px',
              height: '24px',
            },
            '& .MuiChip-root .MuiChip-deleteIcon': {
              margin: '0 2px 0 0',
            },
          }}
          onChange={(e, value) => setSearchingSalesRep(value || '')}
        />

        <Autocomplete
          className={styles['functions']}
          id="charter-company-search"
          options={orders.reduce((unique, order) => {
            return unique.includes(order.charter_company.company_name) ? unique : [...unique, order.charter_company.company_name];
          }, [])}
          noOptionsText={<NoFilterImg style={{ width: '100%', height: '100%' }} />}
          openText='展開'
          value={searchingCharterCompany}
          loading={loading}
          loadingText='載入中...'
          disableClearable
          readOnly={editingOrder}
          multiple
          limitTags={1}
          renderInput={(params) =>
            <TextField {...params}
              label='包車公司'
              size='small'
              variant="standard"
            />
          }
          sx={{
            maxWidth: '270px',
            '& .MuiAutocomplete-input': {
              padding: '8px 0',
            },
            '& .MuiAutocomplete-tag': {
              margin: '0 3px 1px 3px',
              fontSize: '13px',
              fontWeight: 'bold',
            },
            '& .MuiChip-root': {
              height: '24px',
              margin: '0 3px 2px 0',
              backgroundColor: '#ebf1fd',
            },
            '& .MuiChip-label': {
              fontWeight: 'bold',
              padding: editingOrder ? '0 6px 0 6px' : '0 0 0 6px',
              height: '24px',
            },
            '& .MuiChip-root .MuiChip-deleteIcon': {
              margin: '0 2px 0 0',
            },
          }}
          onChange={(e, value) => setSearchingCharterCompany(value || '')}
        />

        <TextField
          className={styles['functions']}
          id="date-search"
          type="date"
          value={searchingVehicleUsage}
          onChange={(e) => {
            setSearchingVehicleUsage(e.target.value || '');
          }}
          label="用車日期"
          size="small"
          variant="standard"
          InputProps={{
            readOnly: editingOrder
          }}
          InputLabelProps={{ shrink: true }}
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '15px',
              fontWeight: 'bold',
            },
          }}
        />

        <Autocomplete
          className={styles['functions']}
          id="area-search"
          options={orders.reduce((unique, order) => {
            return unique.includes(order.area) ? unique : [...unique, order.area];
          }, [])}
          noOptionsText={<NoFilterImg style={{ width: '100%', height: '100%' }} />}
          openText='展開'
          value={searchingArea}
          loading={loading}
          loadingText='載入中...'
          disableClearable
          readOnly={editingOrder}
          multiple
          limitTags={1}
          renderInput={(params) =>
            <TextField {...params}
              label='地區'
              size='small'
              variant="standard"
            />
          }
          sx={{
            maxWidth: '270px',
            '& .MuiAutocomplete-input': {
              padding: '8px 0',
            },
            '& .MuiAutocomplete-tag': {
              margin: '0 3px 1px 3px',
              fontSize: '13px',
              fontWeight: 'bold',
            },
            '& .MuiChip-root': {
              height: '24px',
              margin: '0 3px 2px 0',
              backgroundColor: '#ebf1fd',
            },
            '& .MuiChip-label': {
              fontWeight: 'bold',
              padding: editingOrder ? '0 6px 0 6px' : '0 0 0 6px',
              height: '24px',
            },
            '& .MuiChip-root .MuiChip-deleteIcon': {
              margin: '0 2px 0 0',
            },
          }}
          onChange={(e, value) => setSearchingArea(value || '')}
        />

      </Box>

      <Box className={styles['table-box']}>
        <Card className={styles['table-card']}>
          <CardContent id='table-cardcontent' className={styles['table-cardcontent']} sx={{ '&:last-child': { paddingBottom: 0 } }}>

            <Box className={styles['cardcontent-title-box']}>
              <Typography className={styles['title-label']} sx={{ width: '15.55px', minWidth: '15.55px', flexGrow: '0', padding: '10px', position: 'sticky', left: 0, backgroundColor: '#FFFFFF' }}></Typography>
              <Typography className={styles['title-label']} sx={{ width: '200px', minWidth: '200px', position: 'sticky', left: '35.55px', backgroundColor: '#FFFFFF' }}>
                訂單團號
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: '100px', minWidth: '100px'}}>
                訂單狀態
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: '160px', minWidth: '160px'}}>
                客戶名稱
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: '80px', minWidth: '80px'}}>
                出發日期
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: '80px', minWidth: '80px'}}>
                返回日期
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: '110px', minWidth: '110px'}}>
                承辦業務
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: '75px', minWidth: '75px'}}>
                接送確認
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: '75px', minWidth: '75px'}}>
                群組確認
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: '80px', minWidth: '80px'}}>
                建單時間
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: `${!editingOrder ? '30px' : '60px'}`, minWidth: `${!editingOrder ? '30px' : '60px'}`, position: 'sticky', right: 0, backgroundColor: '#FFFFFF'}}>
                操作
              </Typography>
            </Box>

            <Box className={styles['cardcontent-content-box']}>
              <Box sx={{ width: '100%' }}>

                {!loading && filteredOrders.length > 0 && 
                  filteredOrders.map(order => (
                    <div key={order.uuid}>
                      <Box
                        id={order.uuid}
                        className={styles['content-row']}
                        sx={{ 
                          backgroundColor: openRow === order.uuid ?
                            order.order_status === '未付款' ? '#e5e3e3' :
                            order.order_status === '作業金已付' ? '#e0f2d5' :
                            order.order_status === '尾款已付' ? '#f4dbb4' :
                            order.order_status === '全額付款' ? '#c3d6fa' :
                            order.order_status === '取消' ? '#f6d3d2' :
                            '#FFFFFF'
                          : 
                            '#FFFFFF',
                        }}
                      >
                        <Typography
                          className={styles['content-info']}
                          sx={{ 
                            width: '15.55px',
                            minWidth: '15.55px',
                            flexGrow: '0',
                            padding: '10px',
                            lineHeight: '41px',
                            position: 'sticky',
                            left: 0,
                            zIndex: 2,
                            backgroundColor: openRow === order.uuid ? 
                              order.order_status === '未付款' ? '#e5e3e3' : 
                              order.order_status === '作業金已付' ? '#e0f2d5' : 
                              order.order_status === '尾款已付' ? '#f4dbb4' : 
                              order.order_status === '全額付款' ? '#c3d6fa' : 
                              order.order_status === '取消' ? '#f6d3d2' : 
                              '#FFFFFF' 
                            : 
                              '#FFFFFF'
                          }}
                        >
                          <IconButton sx={{ padding: 0 }} disabled={editingOrder} onClick={() => handleRowClick(order.uuid)}>
                            {openRow === order.uuid ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        </Typography>
                        <Typography
                          className={styles['content-info']} 
                          sx={{
                            width: '200px',
                            minWidth: '200px',
                            lineHeight: '61px',
                            display: 'flex',
                            position: 'sticky', 
                            left: '35.55px',
                            zIndex: 2,
                            backgroundColor: openRow === order.uuid ? 
                              order.order_status === '未付款' ? '#e5e3e3' : 
                              order.order_status === '作業金已付' ? '#e0f2d5' : 
                              order.order_status === '尾款已付' ? '#f4dbb4' : 
                              order.order_status === '全額付款' ? '#c3d6fa' : 
                              order.order_status === '取消' ? '#f6d3d2' : 
                              '#FFFFFF' 
                            : 
                              '#FFFFFF',
                          }}
                        >
                          {editingOrder !== order.uuid ? 
                            <Tooltip
                              title="查看訂單完整資訊"
                              placement="top"
                              slotProps={{
                                popper: {
                                  sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                      {
                                        marginBottom: '-15px',
                                      },
                                  },
                                },
                              }}
                            >
                              <Link href={`/Japan/order-information/${order.uuid}`} underline='none' target="_blank" rel="noopener" className={styles['link-text']}>
                                {order.group_number}
                              </Link>
                            </Tooltip>
                            :
                            <TextField
                              error={!!errors.group_number}
                              helperText={errors.group_number}
                              defaultValue={order.group_number}
                              variant='standard'
                              size='small'
                              sx={{
                                '& .MuiInput-input': {
                                  paddingTop: '3px',
                                  paddingBottom: '3px',
                                },
                              }}
                              onChange={(e) => handleInputChange('group_number', e.target.value)}
                            />
                          }
                        </Typography>
                        <Typography className={styles['content-info']} sx={{ width: '100px', minWidth: '100px'}}>
                          <div className={order.order_status === '未付款' ? styles['status-unpaid'] : order.order_status === '作業金已付' ? styles['status-deposit-paid'] : order.order_status === '尾款已付' ? styles['status-balance-paid'] : order.order_status === '全額付款' ? styles['status-full-paid'] : styles['status-cancelled']}>
                            {order.order_status}
                          </div>
                        </Typography>
                        <Typography className={styles['content-info']} sx={{ width: '160px', minWidth: '160px'}}>
                          {order.customer_name || order.peers_sales.peers_company.peers_company_name +  " - " +  order.peers_sales.peers_sales_name}
                        </Typography>
                        <Typography className={styles['content-info']} sx={{ width: '80px', minWidth: '80px'}}>
                          {order.travel_start_date}
                        </Typography>
                        <Typography className={styles['content-info']} sx={{ width: '80px', minWidth: '80px'}}>
                          {order.travel_end_date}
                        </Typography>
                        <Typography className={styles['content-info']} sx={{ width: '110px', minWidth: '110px'}}>
                          {order.sales_rep.profile?.nickname || order.sales_rep.username}
                        </Typography>
                        <Typography className={styles['content-info']} sx={{ width: '75px', minWidth: '75px'}}>
                          <Switch
                            error={!!errors.orderfortable && !!errors.orderfortable.reception_time_confirmed}
                            helperText={!!errors.orderfortable && errors.orderfortable.reception_time_confirmed}
                            checked={editingOrder === order.uuid ? editedOrder.orderfortable.reception_time_confirmed : order.orderfortable.reception_time_confirmed}
                            onChange={(e) => handleInputChange('orderfortable.reception_time_confirmed', e.target.checked)}
                            color="primary"
                            disabled={editingOrder !== order.uuid}
                            sx={{
                              '& .MuiSwitch-switchBase.Mui-disabled.Mui-checked': {
                                color: 'primary.main',
                              },
                              '& .MuiSwitch-switchBase.Mui-disabled': {
                                color: '#b0b0b0',
                              }
                            }}
                          />
                        </Typography>
                        <Typography className={styles['content-info']} sx={{ width: '75px', minWidth: '75px'}}>
                          <Switch
                            error={!!errors.orderfortable && !!errors.orderfortable.line_group_confirmed}
                            helperText={!!errors.orderfortable && errors.orderfortable.line_group_confirmed}
                            checked={editingOrder === order.uuid ? editedOrder.orderfortable.line_group_confirmed : order.orderfortable.line_group_confirmed}
                            onChange={(e) => handleInputChange('orderfortable.line_group_confirmed', e.target.checked)}
                            color="success"
                            disabled={editingOrder !== order.uuid}
                            sx={{
                              '& .MuiSwitch-switchBase.Mui-disabled.Mui-checked': {
                                color: 'success.main',
                              },
                              '& .MuiSwitch-switchBase.Mui-disabled': {
                                color: '#b0b0b0',
                              }
                            }}
                          />
                        </Typography>
                        <Typography className={styles['content-info']} sx={{ width: '80px', minWidth: '80px'}}>
                          {order.created_at.split('T')[0]}
                        </Typography>
                        <Typography
                          className={styles['content-info']}
                          sx={{ 
                            width: `${!editingOrder ? '30px' : '60px'}`,
                            minWidth: `${!editingOrder ? '30px' : '60px'}`, 
                            lineHeight: '41px', 
                            position: 'sticky', 
                            right: 0, 
                            backgroundColor: openRow === order.uuid ? 
                              order.order_status === '未付款' ? '#e5e3e3' : 
                              order.order_status === '作業金已付' ? '#e0f2d5' : 
                              order.order_status === '尾款已付' ? '#f4dbb4' : 
                              order.order_status === '全額付款' ? '#c3d6fa' : 
                              order.order_status === '取消' ? '#f6d3d2' : 
                              '#FFFFFF' 
                            : 
                              '#FFFFFF'
                          }}
                        >
                          {editingOrder !== order.uuid ?
                            <IconButton sx={{ padding: '3px' }} disabled={editingOrder} onClick={() => handleEditClick(order)}>
                              <Tooltip title="編輯" placement="top">
                                <DriveFileRenameOutline />
                              </Tooltip>
                            </IconButton>
                            :
                            <>
                              <IconButton sx={{ padding: '3px' }} onClick={() => handleSaveClick()}>
                                <Tooltip title="保存" placement="top">
                                  <SaveAs htmlColor='#4476ff' />
                                </Tooltip>
                              </IconButton>
                              <IconButton sx={{ padding: '3px' }} onClick={() => handleCancelClick()}>
                                <Tooltip title="取消" placement="top">
                                  <DoDisturbOn htmlColor='#878ca7' />
                                </Tooltip>
                              </IconButton>
                            </>
                          }
                        </Typography>
                      </Box>

                      <Collapse in={openRow === order.uuid} timeout="auto" unmountOnExit>
                        <Box 
                          className={styles['collapse-box']}
                          sx={{
                            backgroundColor: openRow === order.uuid ? 
                              order.order_status === '未付款' ? '#f1efef' : 
                              order.order_status === '作業金已付' ? '#f6fbf2' : 
                              order.order_status === '尾款已付' ? '#faeeda' : 
                              order.order_status === '全額付款' ? '#dde7fc' : 
                              order.order_status === '取消' ? '#fae7e6' : 
                              '#FFFFFF' 
                            : 
                              '#FFFFFF',
                          }}
                        >
                          <Box
                            className={styles['collapse-title-box']}
                            sx={{ 
                              maxWidth: `${tableWidth}px`,
                              boxShadow: openRow === order.uuid ?
                                order.order_status === '未付款' ? 
                                  'inset 0 10px 10px -10px #e5e3e3, inset 10px 0 10px -10px #e5e3e3, inset -10px 0 10px -10px #e5e3e3' :
                                order.order_status === '作業金已付' ?
                                  'inset 0 10px 10px -10px #e0f2d5, inset 10px 0 10px -10px #e0f2d5, inset -10px 0 10px -10px #e0f2d5' :
                                order.order_status === '尾款已付' ?
                                  'inset 0 10px 10px -10px #f4dbb4, inset 10px 0 10px -10px #f4dbb4, inset -10px 0 10px -10px #f4dbb4' :
                                order.order_status === '全額付款' ?
                                  'inset 0 10px 10px -10px #c3d6fa, inset 10px 0 10px -10px #c3d6fa, inset -10px 0 10px -10px #c3d6fa' :
                                order.order_status === '取消' ?
                                  'inset 0 10px 10px -10px #f6d3d2, inset 10px 0 10px -10px #f6d3d2, inset -10px 0 10px -10px #f6d3d2' :
                                'inset 0 10px 10px -10px #FFFFFF, inset 10px 0 10px -10px #FFFFFF, inset -10px 0 10px -10px #FFFFFF'
                              :
                                'none',
                            }}
                          >
                            <Typography className={styles['collapse-title-label']}>
                              {order.group_number}業務報表資訊 {editingOrder === order.uuid ? '(編輯中. . .請保存)' : ''}
                            </Typography>
                          </Box>

                          <Box
                            className={styles['collapse-content-box']}
                            sx={{
                              maxWidth: `${tableWidth}px`,
                              boxShadow: openRow === order.uuid ?
                                order.order_status === '未付款' ? 
                                  'inset 0 -10px 10px -10px #e5e3e3, inset 10px 0 10px -10px #e5e3e3, inset -10px 0 10px -10px #e5e3e3' :
                                order.order_status === '作業金已付' ?
                                  'inset 0 -10px 10px -10px #e0f2d5, inset 10px 0 10px -10px #e0f2d5, inset -10px 0 10px -10px #e0f2d5' :
                                order.order_status === '尾款已付' ?
                                  'inset 0 -10px 10px -10px #f4dbb4, inset 10px 0 10px -10px #f4dbb4, inset -10px 0 10px -10px #f4dbb4' :
                                order.order_status === '全額付款' ?
                                  'inset 0 -10px 10px -10px #c3d6fa, inset 10px 0 10px -10px #c3d6fa, inset -10px 0 10px -10px #c3d6fa' :
                                order.order_status === '取消' ?
                                  'inset 0 -10px 10px -10px #f6d3d2, inset 10px 0 10px -10px #f6d3d2, inset -10px 0 10px -10px #f6d3d2' :
                                'inset 0 -10px 10px -10px #FFFFFF, inset 10px 0 10px -10px #FFFFFF, inset -10px 0 10px -10px #FFFFFF'
                              :
                                'none',
                            }}
                          >
                            {order?.operation_fee &&
                              <Box className={styles['collapse-content-row']}>
                                <TextField
                                  label="作業金金額"
                                  defaultValue={parseFloat(order.operation_fee).toFixed(2).split('.')[1] === '00' ? parseFloat(order.operation_fee).toFixed(2).split('.')[0] : parseFloat(order.operation_fee).toFixed(2)}
                                  variant='standard'
                                  type='number'
                                  size='small'
                                  focused={false}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: <InputAdornment position="start" style={{ marginRight: '5px' }}><span style={{ padding: '1px 0 3px 0' }}>{order.operation_fee_currency === 'JPY' ? '¥' : 'TWD' ? 'NT$' : ''}</span></InputAdornment>,
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  className={styles['collapse-textfield']}
                                  sx={{
                                    '& .MuiInput-input': {
                                      paddingBottom: '3px',
                                    },
                                  }}
                                />
                                <TextField
                                  label="作業金付款截止日期"
                                  defaultValue={order.operation_fee_due_date}
                                  variant='standard'
                                  type='date'
                                  size='small'
                                  focused={false}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  className={styles['collapse-textfield']}
                                  sx={{
                                    '& .MuiInput-input': {
                                      paddingBottom: '3px',
                                    },
                                  }}
                                />
                                <TextField
                                  label="作業金支付日期"
                                  defaultValue={order.depositslips.filter(depositslip => depositslip.deposit_item === '作業金')[0]?.deposit_date}
                                  variant='standard'
                                  type='date'
                                  size='small'
                                  focused={false}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  className={styles['collapse-textfield']}
                                  sx={{
                                    '& .MuiInput-input': {
                                      paddingBottom: '3px',
                                    },
                                  }}
                                />
                              </Box>
                            }

                            {order?.remaining_fee &&
                              <Box className={styles['collapse-content-row']}>
                                <TextField
                                  label="尾款金額"
                                  defaultValue={parseFloat(order.remaining_fee).toFixed(2).split('.')[1] === '00' ? parseFloat(order.remaining_fee).toFixed(2).split('.')[0] : parseFloat(order.remaining_fee).toFixed(2)}
                                  variant='standard'
                                  type='number'
                                  size='small'
                                  focused={false}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: <InputAdornment position="start" style={{ marginRight: '5px' }}><span style={{ padding: '1px 0 3px 0' }}>{order.remaining_fee_currency === 'JPY' ? '¥' : 'TWD' ? 'NT$' : ''}</span></InputAdornment>,
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  className={styles['collapse-textfield']}
                                  sx={{
                                    '& .MuiInput-input': {
                                      paddingBottom: '3px',
                                    },
                                  }}
                                />
                                <TextField
                                  label="尾款付款截止日期"
                                  defaultValue={order.remaining_fee_due_date}
                                  variant='standard'
                                  type='date'
                                  size='small'
                                  focused={false}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  className={styles['collapse-textfield']}
                                  sx={{
                                    '& .MuiInput-input': {
                                      paddingBottom: '3px',
                                    },
                                  }}
                                />
                                <TextField
                                  label="尾款支付日期"
                                  defaultValue={order.depositslips.filter(depositslip => depositslip.deposit_item === '尾款')[0]?.deposit_date}
                                  variant='standard'
                                  type='date'
                                  size='small'
                                  focused={false}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  className={styles['collapse-textfield']}
                                  sx={{
                                    '& .MuiInput-input': {
                                      paddingBottom: '3px',
                                    },
                                  }}
                                />
                              </Box>
                            }

                            {order?.full_amount &&
                              <Box className={styles['collapse-content-row']}>
                                <TextField
                                  label="全額金額"
                                  defaultValue={parseFloat(order.full_amount).toFixed(2).split('.')[1] === '00' ? parseFloat(order.full_amount).toFixed(2).split('.')[0] : parseFloat(order.full_amount).toFixed(2)}
                                  variant='standard'
                                  type='number'
                                  size='small'
                                  focused={false}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: <InputAdornment position="start" style={{ marginRight: '5px' }}><span style={{ padding: '1px 0 3px 0' }}>{order.full_amount_currency === 'JPY' ? '¥' : 'TWD' ? 'NT$' : ''}</span></InputAdornment>,
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  className={styles['collapse-textfield']}
                                  sx={{
                                    '& .MuiInput-input': {
                                      paddingBottom: '3px',
                                    },
                                  }}
                                />
                                <TextField
                                  label="全額付款截止日期"
                                  defaultValue={order.full_amount_due_date}
                                  variant='standard'
                                  type='date'
                                  size='small'
                                  focused={false}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  className={styles['collapse-textfield']}
                                  sx={{
                                    '& .MuiInput-input': {
                                      paddingBottom: '3px',
                                    },
                                  }}
                                />
                                <TextField
                                  label="全額支付日期"
                                  defaultValue={order.depositslips.filter(depositslip => depositslip.deposit_item === '全額')[0]?.deposit_date}
                                  variant='standard'
                                  type='date'
                                  size='small'
                                  focused={false}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  className={styles['collapse-textfield']}
                                  sx={{
                                    '& .MuiInput-input': {
                                      paddingBottom: '3px',
                                    },
                                  }}
                                />
                              </Box>
                            }

                            <Box className={styles['collapse-content-row']}>
                              <TextField
                                error={!!errors.orderfortable && !!errors.orderfortable.fc_price}
                                helperText={errors.orderfortable && errors.orderfortable.fc_price}
                                label="外幣報價金額"
                                defaultValue={order.orderfortable.fc_price}
                                variant='standard'
                                type='number'
                                size='small'
                                InputProps={{
                                  readOnly: editingOrder !== order.uuid,
                                  onMouseDown: editingOrder !== order.uuid ? (e) => e.preventDefault() : null,
                                  startAdornment: <InputAdornment position="start" style={{ marginRight: '5px' }}><span style={{ padding: '1px 0 3px 0' }}>¥</span></InputAdornment>,
                                }}
                                InputLabelProps={{ shrink: true }}
                                className={styles['collapse-textfield']}
                                sx={{
                                  '& .MuiInput-input': {
                                    paddingBottom: '3px',
                                  },
                                  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                  },
                                  '& input[type="number"]': {
                                      '-moz-appearance': 'textfield'
                                  }
                                }}
                                onChange={(e) => handleInputChange('orderfortable.fc_price', e.target.value)}
                              />
                              <TextField
                                error={!!errors.orderfortable && !!errors.orderfortable.fc_price_estimated}
                                helperText={errors.orderfortable && errors.orderfortable.fc_price_estimated}
                                label="預估外幣報價金額"
                                defaultValue={order.orderfortable.fc_price_estimated}
                                variant='standard'
                                type='number'
                                size='small'
                                InputProps={{
                                  readOnly: editingOrder !== order.uuid,
                                  onMouseDown: editingOrder !== order.uuid ? (e) => e.preventDefault() : null,
                                  startAdornment: <InputAdornment position="start" style={{ marginRight: '5px' }}><span style={{ padding: '1px 0 3px 0' }}>¥</span></InputAdornment>,
                                }}
                                InputLabelProps={{ shrink: true }}
                                className={styles['collapse-textfield']}
                                sx={{
                                  // left: '166.5px',
                                  '& .MuiInput-input': {
                                    paddingBottom: '3px',
                                  },
                                  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                  },
                                  '& input[type="number"]': {
                                      '-moz-appearance': 'textfield'
                                  }
                                }}
                                onChange={(e) => handleInputChange('orderfortable.fc_price_estimated', e.target.value)}
                              />
                              <TextField
                                error={!!errors.charterbooking && !!errors.charterbooking.booking_price}
                                helperText={errors.charterbooking && errors.charterbooking.booking_price}
                                label="外站報價金額"
                                defaultValue={order.charterbooking.booking_price}
                                variant='standard'
                                type='number'
                                size='small'
                                InputProps={{
                                  readOnly: editingOrder !== order.uuid || (!userGroups.includes('管理員') && !userGroups.includes('日本線管理')),
                                  onMouseDown: editingOrder !== order.uuid || (!userGroups.includes('管理員') && !userGroups.includes('日本線管理')) ? (e) => e.preventDefault() : null,
                                  startAdornment: <InputAdornment position="start" style={{ marginRight: '5px' }}><span style={{ padding: '1px 0 3px 0' }}>¥</span></InputAdornment>,
                                }}
                                InputLabelProps={{ shrink: true }}
                                className={styles['collapse-textfield']}
                                sx={{
                                  // left: '318px',
                                  '& .MuiInput-input': {
                                    paddingBottom: '3px',
                                  },
                                  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                  },
                                  '& input[type="number"]': {
                                      '-moz-appearance': 'textfield'
                                  }
                                }}
                                onChange={(e) => handleInputChange('charterbooking.booking_price', e.target.value)}
                              />
                              <TextField
                                label="獲利"
                                value={
                                  editingOrder !== order.uuid ?
                                    (Number(order.orderfortable.fc_price) - (Number(order.charterbooking.booking_price) + 
                                      order.advancepayments.filter(advancepayment => advancepayment.advance_method === '代墊款')
                                        .reduce((acc, advancepayment) => acc + Number(advancepayment.advance_amount), 0)
                                    )).toFixed(2).replace(/\.00$/, '') || ''
                                  :
                                    (Number(editedOrder.orderfortable.fc_price) - (Number(editedOrder.charterbooking.booking_price) + 
                                      editedOrder.advancepayments.filter(advancepayment => advancepayment.advance_method === '代墊款')
                                        .reduce((acc, advancepayment) => acc + Number(advancepayment.advance_amount), 0)
                                    )).toFixed(2).replace(/\.00$/, '') || ''
                                }
                                variant='standard'
                                type='number'
                                size='small'
                                focused={false}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: <InputAdornment position="start" style={{ marginRight: '5px' }}><span style={{ padding: '1px 0 3px 0' }}>¥</span></InputAdornment>,
                                }}
                                InputLabelProps={{ shrink: true }}
                                className={styles['collapse-textfield']}
                                sx={{
                                  '& .MuiInput-input': {
                                    paddingBottom: '3px',
                                  },
                                }}
                              />
                              <TextField
                                label="外站代墊款"
                                value={order.advancepayments.filter(advancepayment => advancepayment.advance_method === '代墊款').reduce((acc, advancepayment) => acc + Number(advancepayment.advance_amount), 0).toFixed(2).replace(/\.00$/, '') || ''}
                                variant='standard'
                                type='number'
                                size='small'
                                focused={false}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: <InputAdornment position="start" style={{ marginRight: '5px' }}><span style={{ padding: '1px 0 3px 0' }}>¥</span></InputAdornment>,
                                }}
                                InputLabelProps={{ shrink: true }}
                                className={styles['collapse-textfield']}
                                sx={{
                                  '& .MuiInput-input': {
                                    paddingBottom: '3px',
                                  },
                                }}
                              />
                              <TextField
                                label="退款"
                                value={order.advancepayments.filter(advancepayment => advancepayment.advance_method === '退款').reduce((acc, advancepayment) => acc + Number(advancepayment.advance_amount), 0).toFixed(2).replace(/\.00$/, '') || ''}
                                variant='standard'
                                type='number'
                                size='small'
                                focused={false}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start" style={{ marginRight: '5px' }}>
                                      {(() => {
                                        const currencies = order.advancepayments
                                          .filter(ap => ap.advance_method === '退款')
                                          .map(ap => ap.advance_currency);
                                        const uniqueCurrencies = [...new Set(currencies)];
                                        if (uniqueCurrencies.length === 1) {
                                          switch (uniqueCurrencies[0]) {
                                            case 'JPY': return '¥';
                                            case 'TWD': return 'NT$';
                                            default: return '¤';
                                          }
                                        }
                                        return '';
                                      })()}
                                    </InputAdornment>
                                  ),
                                }}
                                InputLabelProps={{ shrink: true }}
                                className={styles['collapse-textfield']}
                                sx={{
                                  '& .MuiInput-input': {
                                    paddingBottom: '3px',
                                  },
                                }}
                              />
                              <TextField
                                error={!!errors.orderfortable && !!errors.orderfortable.jp_recive_price}
                                helperText={errors.orderfortable && errors.orderfortable.jp_recive_price}
                                label="外站代收款"
                                defaultValue={order.orderfortable.jp_recive_price || 0}
                                variant='standard'
                                type='number'
                                size='small'
                                InputProps={{
                                  readOnly: editingOrder !== order.uuid,
                                  onMouseDown: editingOrder !== order.uuid ? (e) => e.preventDefault() : null,
                                  startAdornment: <InputAdornment position="start" style={{ marginRight: '5px' }}><span style={{ padding: '1px 0 3px 0' }}>¥</span></InputAdornment>,
                                }}
                                InputLabelProps={{ shrink: true }}
                                className={styles['collapse-textfield']}
                                sx={{
                                  '& .MuiInput-input': {
                                    paddingBottom: '3px',
                                  },
                                  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                  },
                                  '& input[type="number"]': {
                                      '-moz-appearance': 'textfield'
                                  }
                                }}
                                onChange={(e) => handleInputChange('orderfortable.jp_recive_price', e.target.value)}
                              />
                            </Box>

                            <Box className={styles['collapse-content-row']}>
                              <TextField
                                error={!!errors.orderfortable && !!errors.orderfortable.orderfortable_notes}
                                helperText={errors.orderfortable && errors.orderfortable.orderfortable_notes}
                                label="備註"
                                defaultValue={order.orderfortable.orderfortable_notes}
                                variant='standard'
                                size='small'
                                fullWidth
                                multiline
                                InputProps={{
                                  readOnly: editingOrder !== order.uuid,
                                  onMouseDown: editingOrder !== order.uuid ? (e) => e.preventDefault() : null,
                                }}
                                InputLabelProps={{ shrink: true }}
                                className={styles['collapse-textfield']}
                                sx={{
                                  '& .MuiInput-input': {
                                    paddingBottom: '3px',
                                  },
                                }}
                                onChange={(e) => handleInputChange('orderfortable.orderfortable_notes', e.target.value)}
                              />
                            </Box>
                            
                          </Box>
                        </Box>
                      </Collapse>
                    </div>
                  ))
                }

                {!loading && filteredOrders.length === 0 && orders.length > 0 &&
                  <Box className={styles['no-data']}>
                    <NoFilterImg style={{ width: '300px', height: '300px' }} />
                    目前沒有任何符合篩選條件的訂單
                  </Box>
                }

                {!loading && orders.length === 0 &&
                  <Box className={styles['no-data']}>
                    <NoDataImg style={{ width: '300px', height: '300px' }} />
                    目前沒有任何訂單數據
                  </Box>
                }

                {loading &&
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '288px' }}>
                    <CircularProgress />
                  </Box>
                }

              </Box>
            </Box>

          </CardContent>
        </Card>
      </Box>

    </Container>
  );
}

export default OrderTable;
