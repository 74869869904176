import React, { useState, useEffect } from 'react';
import api from '../../config/axiosConfig';
import UserGroupContext from '../../contexts/UserGroupContext';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext';
import {
  Card, CardContent, Container, TextField, Typography, Collapse, Box, IconButton, Divider, Tooltip,
  CircularProgress, Chip, Autocomplete, InputAdornment, Link, Checkbox, FormControlLabel, tooltipClasses
} from '@mui/material';
import { DoDisturbOn, ExpandMore, ExpandLess, SaveAs, Verified, NewReleases, DeleteSweep, TimeToLeave, EmojiTransportation, FilterList, DriveFileRenameOutline } from '@mui/icons-material';
import styles from './css/AccountingTable.module.css';
import { ReactComponent as NoDataImg } from '../../pages/img/NoData.svg';
import { ReactComponent as NoFilterImg } from '../../pages/img/NoFilter.svg';
import { ReactComponent as NoSearchImg } from './img/searching.svg';

function deepCompare(oldObj, newObj, result = {}) {
  Object.keys({ ...oldObj, ...newObj }).forEach(key => {
      const oldVal = oldObj[key];
      const newVal = newObj[key];

      if (Array.isArray(oldVal) && Array.isArray(newVal)) {
          const arrayChanges = [];
          const maxLength = Math.max(oldVal.length, newVal.length);
          for (let i = 0; i < maxLength; i++) {
              if (i >= oldVal.length) {
                  arrayChanges.push(newVal[i]);
              } else if (i >= newVal.length) {
              } else {
                  const itemChanges = {};
                  deepCompare(oldVal[i], newVal[i], itemChanges);
                  if (Object.keys(itemChanges).length > 0) {
                      if (newVal[i].id !== undefined) {
                          itemChanges['id'] = newVal[i].id;
                      }
                      arrayChanges.push(itemChanges);
                  }
              }
          }
          if (arrayChanges.length > 0) {
              result[key] = arrayChanges;
          }
      } else if (oldVal && typeof oldVal === 'object' && newVal && typeof newVal === 'object') {
          const nestedChanges = {};
          deepCompare(oldVal, newVal, nestedChanges);
          if (Object.keys(nestedChanges).length > 0) {
              result[key] = nestedChanges;
          }
      } else if (oldVal !== newVal) {
          result[key] = newVal;
      }
  });

  return result;
}

function AccountingTable() {
  const userGroups = React.useContext(UserGroupContext);
  const [orders, setOrders] = useState([]);
  const [errors, setErrors] = useState({});
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [options, setOptions] = useState([]);
  const [searchingGroupNumber, setSearchingGroupNumber] = useState('');
  const [searchingOrderStatus, setSearchingOrderStatus] = useState([]);
  const [searchingChecking, setSearchingChecking] = useState([]);
  const [searchingSalesRep, setSearchingSalesRep] = useState([]);
  const [searchingCharterCompany, setSearchingCharterCompany] = useState([]);
  const [searchingArea, setSearchingArea] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [editingOrder, setEditingOrder] = useState(null);
  const [editedOrder, setEditedOrder] = useState({});
  const [openRow, setOpenRow] = useState(null);
  const [loading, setLoading] = useState(null);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [abortController, setAbortController] = useState(null);
  const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();
  const years = Array.from({ length: new Date().getFullYear() - 2022 + 1 }, (_, i) => 2023 + i);
  const [tableWidth, setTableWidth] = useState(0);
  
  useEffect(() => {

    const updateDateFromHash = () => {
      const hash = window.location.hash;

      if (hash && /^#\d{4}-\d{2}$/.test(hash)) {
        const [newYear, newMonth] = hash.substring(1).split('-');
        setYear(parseInt(newYear, 10));
        setMonth(parseInt(newMonth, 10));
      }
    };

    const currentDate = new Date();
    if (!window.location.hash || !/^#\d{4}-\d{2}$/.test(window.location.hash)) {
      const newYear = currentDate.getFullYear();
      const newMonth = currentDate.getMonth() + 1;
      setYear(newYear);
      setMonth(newMonth);
      window.location.hash = `#${newYear}-${String(newMonth).padStart(2, '0')}`;
    } else {
      updateDateFromHash();
    }

    const fetchOption = async () => {
      try {
        const optionResponse = await api.get('/japan/api/accounting-table-options/');
        setOptions(optionResponse.data);
      } catch (error) {
        console.error('Failed to fetch options:', error);
      }
    };

    fetchOption();
    setHasUnsavedChanges(false);

    const container = document.getElementById('table-cardcontent');
    if (container) {
      // console.log('監聽視窗大小變化');
      const resizeObserver = new ResizeObserver(() => {
        const width = container.getBoundingClientRect().width;
        if (container.scrollHeight > container.clientHeight) {
          setTableWidth(width - 30 - 17);
          // console.log('有垂直滾動條，寬度減去滾動條寬度:', width - 17);
        } else {
          setTableWidth(width - 30);
          // console.log('無垂直滾動條，寬度不變:', width);
        }
      });
      resizeObserver.observe(container);

      return () => {
        resizeObserver.disconnect();
      };
    }

    window.addEventListener('hashchange', updateDateFromHash);

    return () => {
      window.removeEventListener('hashchange', updateDateFromHash);
    };
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    
  }, [hasUnsavedChanges]);

  useEffect(() => {
    if (year && month >= 1) {
      if (abortController) {
        abortController.abort();
      }
      window.location.hash = `#${year}-${String(month).padStart(2, '0')}`;
      document.title = `日本線對帳表 - ${year}-${String(month).padStart(2, '0')}`;
      setLoading(true);
      setOpenRow(null);
      setOrders([]);
      handleClearFilters();
      fetchOrders(year, month);
    }
  // eslint-disable-next-line
  }, [year, month]);

  useEffect(() => {
    let result = [...orders];

    if (searchingGroupNumber) {
      result = result.filter(order => order.group_number.includes(searchingGroupNumber));
    }

    if (searchingOrderStatus.length > 0) {
      result = result.filter(order =>
        searchingOrderStatus.some(status =>
          order.order_status.includes(status)
        )
      );
    }

    if (searchingChecking.length > 0) {
      result = result.filter(order =>
        searchingChecking.some(checking => {
          if (checking.includes(':')) {
            const [type, audit] = checking.split(':');
            if (!type || !audit) return false;
    
            if (type === '入款單') {
              return order.depositslips.some(deposit => {
                if (audit === '會計') {
                  return !deposit.accounting_audit;
                } else if (audit === '主管') {
                  return !deposit.manager_audit;
                }
                return false;
              });
            }
    
            if (type === '代墊(退)款單') {
              return order.advancepayments.some(advance => {
                if (audit === '會計') {
                  return !advance.accounting_audit;
                } else if (audit === '主管') {
                  return !advance.manager_audit;
                }
                return false;
              });
            }
          } else {
            const audit = checking;
            return (
              order.depositslips.some(deposit => {
                if (audit === '會計') {
                  return !deposit.accounting_audit;
                } else if (audit === '主管') {
                  return !deposit.manager_audit;
                }
                return false;
              }) ||
              order.advancepayments.some(advance => {
                if (audit === '會計') {
                  return !advance.accounting_audit;
                } else if (audit === '主管') {
                  return !advance.manager_audit;
                }
                return false;
              })
            );
          }
          return false;
        })
      );
    }

    if (searchingSalesRep.length > 0) {
      result = result.filter(order =>
        searchingSalesRep.some(salesRep =>
          order.sales_rep.profile?.nickname.includes(salesRep) || order.sales_rep.username.includes(salesRep)
        )
      );
    }

    if (searchingCharterCompany.length > 0) {
      result = result.filter(order =>
        searchingCharterCompany.some(charterCompany =>
          order.charter_company.company_name.includes(charterCompany)
        )
      );
    }

    if (searchingArea.length > 0) {
      result = result.filter(order =>
        searchingArea.some(area =>
          order.area.includes(area)
        )
      );
    }

    setFilteredOrders(result);
  }, [orders, searchingGroupNumber, searchingOrderStatus, searchingChecking, searchingSalesRep, searchingCharterCompany, searchingArea]);

  const fetchOrders = async (year, month) => {
    const controller = new AbortController();
    setAbortController(controller);
    
    setOrders([]);
    setLoading(true);
    try {
      const orderResponse = await api.get(`/japan/api/Order/?from=${'AccountingTable'}&year=${year}&month=${month}`, { signal: controller.signal });
      if (!controller.signal.aborted) {
        setOrders(orderResponse.data);
        setFilteredOrders(orderResponse.data);
        setLoading(false);
      }
    } catch (error) {
      if (error.name === 'CanceledError') {
        console.log('終止獲取', year, '年', month, '月的訂單');
      } else {
        console.error('獲取訂單時發生錯誤:', error);
      }
    }
  };

  const handleClearFilters = () => {
    setSearchingGroupNumber('');
    setSearchingOrderStatus([]);
    setSearchingChecking([]);
    setSearchingSalesRep([]);
    setSearchingCharterCompany([]);
    setSearchingArea([]);
  };

  const handleRowClick = (uuid) => {
    if (openRow === uuid) {
      setOpenRow(null);
    } else {
      setOpenRow(uuid);
    }
  };

  const handleEditClick = (order) => {
    setEditingOrder(order.uuid);
    setEditedOrder(order);
    setOpenRow(order.uuid);
    setHasUnsavedChanges(true);
  };

  const handleInputChange = (field, value, index = null) => {
    if (field.includes('.')) {
      const keys = field.split('.');
      const lastKey = keys.pop();
  
      setEditedOrder(prevState => {
        let updatedState = JSON.parse(JSON.stringify(prevState));
  
        let currentLevel = updatedState;
  
        keys.forEach((key, idx) => {
          if (index !== null && idx === keys.length - 1) {
            currentLevel[key][index] = {
              ...currentLevel[key][index],
              [lastKey]: value,
            };
          } else {
            currentLevel[key] = {
              ...currentLevel[key],
            };
            currentLevel = currentLevel[key];
          }
        });
  
        if (index === null) {
          currentLevel[lastKey] = value;
        }
  
        return updatedState;
      });
    } else {
      setEditedOrder({
        ...editedOrder,
        [field]: value,
      });
    }
  };

  const handleCancelClick = () => {
    setEditingOrder(null);
    setEditedOrder({});
    setErrors({});
    setHasUnsavedChanges(false);
  };

  const handleSaveClick = async () => {
    const now = Date.now();
    if (now - lastClickTime < 3000) {
      console.log('請勿連續點擊保存按鈕');
      return;
    }

    setLastClickTime(now);

    try {
      const changes ={}
      deepCompare(orders.find(order => order.uuid === editingOrder), editedOrder, changes);
      // console.log('變更:', changes);

      const changedOrderData = {
        ...changes,
        uuid: editingOrder,
        ...(changes.charter_company && { charter_company_id: changes.charter_company.id}),
        ...(changes.vehicle_type && { vehicle_type_id: changes.vehicle_type.id }),
        ...(changes.warning_type && { warning_type_id: changes.warning_type.id }),
        ...(changes.policy_type && { policy_type_id: changes.policy_type.id }),
        ...(changes.remittance_info && { remittance_info_id: changes.remittance_info.id }),
        ...(changes.sales_rep && { sales_rep_id: changes.sales_rep.id }),
      };

      if (Object.keys(changedOrderData).length === 1) {
        setEditingOrder(null);
        setEditedOrder({});
        setErrors({});
        setHasUnsavedChanges(false);
        return;
      }

      const response = await api.patch(`/japan/api/Order/${editingOrder}/`, changedOrderData);
      
      if (response.status === 200) {
        setOrders(orders.map(order => (order.uuid === editingOrder ? response.data : order)));
        setEditingOrder(null);
        setEditedOrder({});
        setErrors({});
        setHasUnsavedChanges(false);
      } else {
        console.error('Failed to save changes:', response);
        setErrors({ general: '保存失敗，請重試。' });
      }
    } catch (error) {
      console.error('Error saving changes:', error);
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      } else {
        setErrors({ general: '發生未知錯誤，請重試或聯繫相關人員。' });
      }
    }
  };

  return (
    <Container maxWidth={false} id='main-container'>
      
      <Box className={styles['tips-box']}>

        <Typography className={styles['page-title']}>
          日本線對帳表
        </Typography>

        <Box className={styles['tips-row']}>
          <Chip
            label={'訂單: ' + filteredOrders.length}
            variant='outlined'
            sx={{
              '& .MuiChip-label': {
                fontSize: '13px',
                fontWeight: 'bold',
                lineHeight: '20px',
                padding: '0 9px',
              }
            }}
          />

          <Chip
            label={'取消: ' + filteredOrders.filter(order => order.order_status === '取消').length}
            variant='outlined'
            className={styles['tips-cancelled']}
            sx={{
              '& .MuiChip-label': {
                padding: '0 9px',
              }
            }}
          />

          <Chip
            label={'未付款: ' + filteredOrders.filter(order => order.order_status === '未付款').length}
            variant='outlined'
            className={styles['tips-unpaid']}
            sx={{
              '& .MuiChip-label': {
                padding: '0 9px',
              }
            }}
          />

          <Chip
            label={'作業金已付: ' + filteredOrders.filter(order => order.order_status === '作業金已付').length}
            variant='outlined'
            className={styles['tips-deposit-paid']}
            sx={{
              '& .MuiChip-label': {
                padding: '0 9px',
              }
            }}
          />

          <Chip
            label={'尾款已付: ' + filteredOrders.filter(order => order.order_status === '尾款已付').length}
            variant='outlined'
            className={styles['tips-balance-paid']}
            sx={{
              '& .MuiChip-label': {
                padding: '0 9px',
              }
            }}
          />

          <Chip
            label={'全額付款: ' + filteredOrders.filter(order => order.order_status === '全額付款').length}
            variant='outlined'
            className={styles['tips-full-paid']}
            sx={{
              '& .MuiChip-label': {
                padding: '0 9px',
              }
            }}
          />

          <Chip
            icon={<TimeToLeave sx={{ fontSize: '20px' }} />}
            label={'接送用車: ' + filteredOrders.filter(order => order.order_status !== '取消').reduce((total, order) => total + order.itineraries.filter(itinerary => itinerary.service_info === '接送').length, 0)}
            variant='outlined'
            className={styles['tips-reception']}
            sx={{
              '& .MuiChip-label': {
                fontSize: '13px',
                fontWeight: 'bold',
                lineHeight: '20px',
                padding: '0 9px',
              }
            }}
          />

          <Chip
            icon={<EmojiTransportation sx={{ fontSize: '20px' }} />}
            label={'全日包車: ' + filteredOrders.filter(order => order.order_status !== '取消').reduce((total, order) => total + order.itineraries.filter(itinerary => itinerary.service_info === '全日包車').length, 0)}
            variant='outlined'
            className={styles['tips-charter']}
            sx={{
              '& .MuiChip-label': {
                fontSize: '13px',
                fontWeight: 'bold',
                lineHeight: '20px',
                padding: '0 9px',
              }
            }}
          />

        </Box>
        
        <Chip
          icon={<FilterList sx={{ fontSize: '20px' }} />}
          label={(
            searchingGroupNumber || searchingOrderStatus.length > 0 || searchingChecking.length > 0 || searchingSalesRep.length > 0 || searchingCharterCompany.length > 0 || searchingArea.length > 0 ?
              '篩選過濾' +
              [
                searchingGroupNumber ? '團號: ' + searchingGroupNumber : '',
                searchingOrderStatus.length > 0 ? '狀態: ' + searchingOrderStatus.join('、') : '',
                searchingChecking.length > 0 ? '未審核: ' + searchingChecking.join('、') : '',
                searchingSalesRep.length > 0 ? '承辦業務: ' + searchingSalesRep.join('、') : '',
                searchingCharterCompany.length > 0 ? '包車公司: ' + searchingCharterCompany.join('、') : '',
                searchingArea.length > 0 ? '地區: ' + searchingArea.join('、') : ''
              ].filter(Boolean).join(' / ')
              : '篩選過濾: 無'
          )}
          variant="outlined"
          className={styles['tips-filter']}
          onDelete={
            (searchingGroupNumber || searchingOrderStatus.length > 0 || searchingChecking.length > 0 || searchingSalesRep.length > 0 || searchingCharterCompany.length > 0 || searchingArea.length > 0) && !editingOrder
              ? handleClearFilters
              : null
          }
          deleteIcon={
            <Tooltip title="點擊清除篩選條件" placement="right">
              <DeleteSweep />
            </Tooltip>
          }
        />

      </Box>

      <Box className={styles['choose-box']}>

        <Box className={styles['choose-row']} sx={{ padding: '0 100px' }}>

          <Box className={styles['hang-design']} />
          <Box className={styles['hang-design']} />

        </Box>

        <Box className={styles['choose-row']} sx={{ padding: '0 74.5px' }}>
          <Autocomplete
            id="year-select"
            options={years}
            getOptionLabel={(option) => String(option)}
            openText='展開'
            value={year}
            loading={loading}
            loadingText='載入中...'
            disableClearable
            readOnly={editingOrder}
            renderInput={(params) =>
              <TextField {...params}
                helperText='請選擇年份'
                size='small'
                variant="outlined"
              />
            }
            sx={{
              width: '100px',
              '& .MuiAutocomplete-input': {
                fontSize: '15px',
                fontWeight: 'bold',
              },
            }}
            onChange={(e, value) => setYear(value || null)}
          />

          <Typography className={styles['tips-text']}>
            上方數據皆為篩選後的訂單進行統計，用車統計已過濾取消訂單。
          </Typography>

          <Autocomplete
            id="month-select"
            options={Array.from({ length: 12 }, (_, i) => i + 1)}
            getOptionLabel={(option) => String(option)}
            openText='展開'
            value={month}
            loading={loading}
            loadingText='載入中...'
            disableClearable
            readOnly={editingOrder}
            renderInput={(params) =>
              <TextField {...params}
                helperText='請選擇月份'
                size='small'
                variant="outlined"
              />
            }
            sx={{
              width: '100px',
              '& .MuiAutocomplete-input': {
                fontSize: '15px',
                fontWeight: 'bold',
              },
            }}
            onChange={(e, value) => setMonth(value || null)}
          />
        </Box>
        
      </Box>

      <Box className={styles['function-box']}>

        <Autocomplete
          className={styles['functions']}
          id="group-number-search"
          options={orders.map(order => order.group_number)}
          noOptionsText={<NoFilterImg style={{ width: '100%', height: '100%' }} />}
          openText='展開'
          loading={loading}
          loadingText='載入中...'
          readOnly={editingOrder}
          renderInput={(params) => 
            <TextField {...params}
              label='團號'
              size='small'
              variant="standard"
            />
          }
          sx={{
            '& .MuiAutocomplete-input': {
              fontSize: '15px',
              fontWeight: 'bold',
            },
          }}
          onChange={(e, value) => setSearchingGroupNumber(value || '')}
        />

        <Autocomplete
          className={styles['functions']}
          id="order-status-search"
          // options={['未付款', '作業金已付', '尾款已付', '全額付款', '取消']}
          options={orders.reduce((unique, order) => {
            return unique.includes(order.order_status) ? unique : [...unique, order.order_status];
          }, [])}
          noOptionsText={<NoFilterImg style={{ width: '100%', height: '100%' }} />}
          openText='展開'
          value={searchingOrderStatus}
          loading={loading}
          loadingText='載入中...'
          disableClearable
          readOnly={editingOrder}
          multiple
          limitTags={1}
          renderInput={(params) => (
            <TextField
              {...params}
              label="狀態"
              size="small"
              variant="standard"
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              let color;
              let backgroundColor;
              switch (option) {
                case '未付款':
                  color = '4f4d4d';
                  backgroundColor = '#b8b5b5';
                  break;
                case '作業金已付':
                  color = '#305123';
                  backgroundColor = '#94cf73';
                  break;
                case '尾款已付':
                  color = '#703a1e';
                  backgroundColor = '#e8ab65';
                  break;
                case '全額付款':
                  color = '243484';
                  backgroundColor = '#5e91f0';
                  break;
                case '取消':
                  color = '#702d2c';
                  backgroundColor = '#e38785';
                  break;
                default:
                  color = 'default';
                  backgroundColor = 'default';
              }
              
              return (
                <Chip
                  key={option}
                  label={option}
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor: backgroundColor,
                    color: color,
                  }}
                />
              );
            })
          }
          sx={{
            maxWidth: '190px',
            '& .MuiAutocomplete-input': {
              padding: '8px 0',
            },
            '& .MuiAutocomplete-tag': {
              margin: '0 3px 1px 3px',
              fontSize: '13px',
              fontWeight: 'bold',
            },
            '& .MuiChip-root': {
              height: '24px',
              margin: '0 3px 2px 0',
            },
            '& .MuiChip-label': {
              fontWeight: 'bold',
              padding: editingOrder ? '0 6px 0 6px' : '0 0 0 6px',
              height: '24px',
            },
            '& .MuiChip-root .MuiChip-deleteIcon': {
              margin: '0 2px 0 0',
            },
          }}
          onChange={(e, value) => setSearchingOrderStatus(value || '')}
        />

        <Autocomplete
          className={styles['functions']}
          id="checking-search"
          options={orders.length > 0 ? [
            '會計', '主管', '入款單:會計', '入款單:主管', '代墊(退)款單:會計', '代墊(退)款單:主管'
          ] : []}
          noOptionsText={<NoFilterImg style={{ width: '100%', height: '100%' }} />}
          openText='展開'
          value={searchingChecking}
          loading={loading}
          loadingText='載入中...'
          disableClearable
          readOnly={editingOrder}
          multiple
          limitTags={1}
          renderInput={(params) =>
            <TextField {...params}
              label='未審核'
              size='small'
              variant="standard"
            />
          }
          sx={{
            maxWidth: '225px',
            '& .MuiAutocomplete-input': {
              padding: '8px 0',
            },
            '& .MuiAutocomplete-tag': {
              margin: '0 3px 1px 3px',
              fontSize: '13px',
              fontWeight: 'bold',
            },
            '& .MuiChip-root': {
              height: '24px',
              margin: '0 3px 2px 0',
              backgroundColor: '#ebf1fd',
            },
            '& .MuiChip-label': {
              fontWeight: 'bold',
              padding: editingOrder ? '0 6px 0 6px' : '0 0 0 6px',
              height: '24px',
            },
            '& .MuiChip-root .MuiChip-deleteIcon': {
              margin: '0 2px 0 0',
            },
          }}
          onChange={(e, value) => setSearchingChecking(value || '')}
        />

        <Autocomplete
          className={styles['functions']}
          id="sales-rep-search"
          options={orders.reduce((unique, order) => {
            return unique.includes(order.sales_rep.profile?.nickname || order.sales_rep.username) ? unique : [...unique, order.sales_rep.profile?.nickname || order.sales_rep.username];
          }, [])}
          noOptionsText={<NoFilterImg style={{ width: '100%', height: '100%' }} />}
          openText='展開'
          value={searchingSalesRep}
          loading={loading}
          loadingText='載入中...'
          disableClearable
          readOnly={editingOrder}
          multiple
          limitTags={1}
          renderInput={(params) =>
            <TextField {...params}
              label='承辦業務'
              size='small'
              variant="standard"
            />
          }
          sx={{
            maxWidth: '270px',
            '& .MuiAutocomplete-input': {
              padding: '8px 0',
            },
            '& .MuiAutocomplete-tag': {
              margin: '0 3px 1px 3px',
              fontSize: '13px',
              fontWeight: 'bold',
            },
            '& .MuiChip-root': {
              height: '24px',
              margin: '0 3px 2px 0',
              backgroundColor: '#ebf1fd',
            },
            '& .MuiChip-label': {
              fontWeight: 'bold',
              padding: editingOrder ? '0 6px 0 6px' : '0 0 0 6px',
              height: '24px',
            },
            '& .MuiChip-root .MuiChip-deleteIcon': {
              margin: '0 2px 0 0',
            },
          }}
          onChange={(e, value) => setSearchingSalesRep(value || '')}
        />

        <Autocomplete
          className={styles['functions']}
          id="charter-company-search"
          options={orders.reduce((unique, order) => {
            return unique.includes(order.charter_company.company_name) ? unique : [...unique, order.charter_company.company_name];
          }, [])}
          noOptionsText={<NoFilterImg style={{ width: '100%', height: '100%' }} />}
          openText='展開'
          value={searchingCharterCompany}
          loading={loading}
          loadingText='載入中...'
          disableClearable
          readOnly={editingOrder}
          multiple
          limitTags={1}
          renderInput={(params) =>
            <TextField {...params}
              label='包車公司'
              size='small'
              variant="standard"
            />
          }
          sx={{
            maxWidth: '270px',
            '& .MuiAutocomplete-input': {
              padding: '8px 0',
            },
            '& .MuiAutocomplete-tag': {
              margin: '0 3px 1px 3px',
              fontSize: '13px',
              fontWeight: 'bold',
            },
            '& .MuiChip-root': {
              height: '24px',
              margin: '0 3px 2px 0',
              backgroundColor: '#ebf1fd',
            },
            '& .MuiChip-label': {
              fontWeight: 'bold',
              padding: editingOrder ? '0 6px 0 6px' : '0 0 0 6px',
              height: '24px',
            },
            '& .MuiChip-root .MuiChip-deleteIcon': {
              margin: '0 2px 0 0',
            },
          }}
          onChange={(e, value) => setSearchingCharterCompany(value || '')}
        />

        <Autocomplete
          className={styles['functions']}
          id="area-search"
          options={orders.reduce((unique, order) => {
            return unique.includes(order.area) ? unique : [...unique, order.area];
          }, [])}
          noOptionsText={<NoFilterImg style={{ width: '100%', height: '100%' }} />}
          openText='展開'
          value={searchingArea}
          loading={loading}
          loadingText='載入中...'
          disableClearable
          readOnly={editingOrder}
          multiple
          limitTags={1}
          renderInput={(params) =>
            <TextField {...params}
              label='地區'
              size='small'
              variant="standard"
            />
          }
          sx={{
            maxWidth: '270px',
            '& .MuiAutocomplete-input': {
              padding: '8px 0',
            },
            '& .MuiAutocomplete-tag': {
              margin: '0 3px 1px 3px',
              fontSize: '13px',
              fontWeight: 'bold',
            },
            '& .MuiChip-root': {
              height: '24px',
              margin: '0 3px 2px 0',
              backgroundColor: '#ebf1fd',
            },
            '& .MuiChip-label': {
              fontWeight: 'bold',
              padding: editingOrder ? '0 6px 0 6px' : '0 0 0 6px',
              height: '24px',
            },
            '& .MuiChip-root .MuiChip-deleteIcon': {
              margin: '0 2px 0 0',
            },
          }}
          onChange={(e, value) => setSearchingArea(value || '')}
        />

      </Box>

      <Box className={styles['table-box']}>
        <Card className={styles['table-card']}>
          <CardContent id='table-cardcontent' className={styles['table-cardcontent']} sx={{ '&:last-child': { paddingBottom: 0 } }}>

            <Box className={styles['cardcontent-title-box']}>
              <Typography className={styles['title-label']} sx={{ width: '15.55px', minWidth: '15.55px', flexGrow: '0', padding: '10px', position: 'sticky', left: 0, backgroundColor: '#FFFFFF' }}></Typography>
              <Typography className={styles['title-label']} sx={{ width: '200px', minWidth: '200px', position: 'sticky', left: '35.55px', backgroundColor: '#FFFFFF' }}>
                訂單團號
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: '130px', minWidth: '130px'}}>
                訂單狀態
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: '160px', minWidth: '160px'}}>
                客戶名稱
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: '80px', minWidth: '80px'}}>
                承辦業務
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: '80px', minWidth: '80px'}}>
                出發日期
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: '80px', minWidth: '80px'}}>
                返回日期
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: '80px', minWidth: '80px'}}>
                建單時間
              </Typography>
              <Typography className={styles['title-label']} sx={{ width: `${!editingOrder ? '30px' : '60px'}`, minWidth: `${!editingOrder ? '30px' : '60px'}`, position: 'sticky', right: 0, backgroundColor: '#FFFFFF'}}>
                操作
              </Typography>
            </Box>

            <Box className={styles['cardcontent-content-box']}>
              <Box sx={{ width: '100%' }}>

                {!loading && filteredOrders.length > 0 && 
                  filteredOrders.map(order => (
                    <div key={order.uuid}>
                      <Box
                        id={order.uuid}
                        className={styles['content-row']}
                        sx={{ 
                          backgroundColor: openRow === order.uuid ?
                            order.order_status === '未付款' ? '#e5e3e3' :
                            order.order_status === '作業金已付' ? '#e0f2d5' :
                            order.order_status === '尾款已付' ? '#f4dbb4' :
                            order.order_status === '全額付款' ? '#c3d6fa' :
                            order.order_status === '取消' ? '#f6d3d2' :
                            '#FFFFFF'
                          : 
                            '#FFFFFF',
                        }}
                      >
                        <Typography
                          className={styles['content-info']}
                          sx={{ 
                            width: '15.55px',
                            minWidth: '15.55px',
                            flexGrow: '0',
                            padding: '10px',
                            lineHeight: '41px',
                            position: 'sticky',
                            left: 0,
                            zIndex: 2,
                            backgroundColor: openRow === order.uuid ? 
                              order.order_status === '未付款' ? '#e5e3e3' : 
                              order.order_status === '作業金已付' ? '#e0f2d5' : 
                              order.order_status === '尾款已付' ? '#f4dbb4' : 
                              order.order_status === '全額付款' ? '#c3d6fa' : 
                              order.order_status === '取消' ? '#f6d3d2' : 
                              '#FFFFFF' 
                            : 
                              '#FFFFFF'
                          }}
                        >
                          <IconButton sx={{ padding: 0 }} disabled={editingOrder} onClick={() => handleRowClick(order.uuid)}>
                            {openRow === order.uuid ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        </Typography>
                        <Typography
                          className={styles['content-info']} 
                          sx={{
                            width: '200px',
                            minWidth: '200px',
                            lineHeight: '61px',
                            display: 'flex',
                            position: 'sticky', 
                            left: '35.55px', 
                            zIndex: 2,
                            backgroundColor: openRow === order.uuid ? 
                              order.order_status === '未付款' ? '#e5e3e3' : 
                              order.order_status === '作業金已付' ? '#e0f2d5' : 
                              order.order_status === '尾款已付' ? '#f4dbb4' : 
                              order.order_status === '全額付款' ? '#c3d6fa' : 
                              order.order_status === '取消' ? '#f6d3d2' : 
                              '#FFFFFF' 
                            : 
                              '#FFFFFF',
                          }}
                        >
                          {editingOrder !== order.uuid ?
                            <Tooltip
                              title="查看訂單完整資訊"
                              placement="top"
                              slotProps={{
                                popper: {
                                  sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                      {
                                        marginBottom: '-15px',
                                      },
                                  },
                                },
                              }}
                            >
                              <Link href={`/Japan/order-information/${order.uuid}`} underline='none' target="_blank" rel="noopener" className={styles['link-text']}>
                                {order.group_number}
                              </Link>
                            </Tooltip>
                            :
                            <TextField
                              error={!!errors.group_number}
                              helperText={errors.group_number}
                              defaultValue={order.group_number}
                              variant='standard'
                              size='small'
                              className={styles['collapse-textfield']}
                              sx={{
                                '& .MuiInput-input': {
                                  paddingTop: '3px',
                                  paddingBottom: '3px',
                                },
                              }}
                              onChange={(e) => handleInputChange('group_number', e.target.value)}
                            />
                          }
                        </Typography>
                        <Typography className={styles['content-info']} sx={{ width: '130px', minWidth: '130px'}}>
                          {editingOrder !== order.uuid ?
                            <div className={order.order_status === '未付款' ? styles['status-unpaid'] : order.order_status === '作業金已付' ? styles['status-deposit-paid'] : order.order_status === '尾款已付' ? styles['status-balance-paid'] : order.order_status === '全額付款' ? styles['status-full-paid'] : styles['status-cancelled']}>
                              {order.order_status}
                            </div>
                            :
                            <Autocomplete
                              options={options.order_options.order_status}
                              getOptionLabel={(option) => option.label}
                              openText='展開'
                              defaultValue={options.order_options.order_status.find(option => option.label === order.order_status)}
                              filterOptions={(options) => options.filter(option => option.label !== editedOrder.order_status)}
                              loading={loading}
                              loadingText='載入中...'
                              disableClearable
                              renderInput={(params) =>
                                <TextField {...params}
                                  size='small'
                                  variant="standard"
                                />
                              }
                              className={styles['collapse-textfield']}
                              sx={{
                                '& .MuiAutocomplete-input': {
                                  fontSize: '15px',
                                  fontWeight: 'bold',
                                  color: editedOrder.order_status === '未付款' ? '#4f4d4d' : editedOrder.order_status === '作業金已付' ? '#305123' : editedOrder.order_status === '尾款已付' ? '#703a1e' : editedOrder.order_status === '全額付款' ? '#243484' : editedOrder.order_status === '取消' ? '#702d2c' : '#4f4d4d',
                                },
                              }}
                              onChange={(e, value) => handleInputChange('order_status', value.label)}
                            />
                          }
                        </Typography>
                        <Typography className={styles['content-info']} sx={{ width: '160px', minWidth: '160px'}}>
                          {order.customer_name || order.peers_sales.peers_company.peers_company_name +  " - " +  order.peers_sales.peers_sales_name}
                        </Typography>
                        <Typography className={styles['content-info']} sx={{ width: '80px', minWidth: '80px'}}>
                          {order.sales_rep.profile?.nickname || order.sales_rep.username}
                        </Typography>
                        <Typography className={styles['content-info']} sx={{ width: '80px', minWidth: '80px'}}>
                          {order.travel_start_date}
                        </Typography>
                        <Typography className={styles['content-info']} sx={{ width: '80px', minWidth: '80px'}}>
                          {order.travel_end_date}
                        </Typography>
                        <Typography className={styles['content-info']} sx={{ width: '80px', minWidth: '80px'}}>
                          {order.created_at.split('T')[0]}
                        </Typography>
                        <Typography
                          className={styles['content-info']}
                          sx={{ 
                            width: `${!editingOrder ? '30px' : '60px'}`,
                            minWidth: `${!editingOrder ? '30px' : '60px'}`, 
                            lineHeight: '41px', 
                            position: 'sticky', 
                            right: 0, 
                            backgroundColor: openRow === order.uuid ? 
                              order.order_status === '未付款' ? '#e5e3e3' : 
                              order.order_status === '作業金已付' ? '#e0f2d5' : 
                              order.order_status === '尾款已付' ? '#f4dbb4' : 
                              order.order_status === '全額付款' ? '#c3d6fa' : 
                              order.order_status === '取消' ? '#f6d3d2' : 
                              '#FFFFFF' 
                            : 
                              '#FFFFFF'
                          }}
                        >
                          {editingOrder !== order.uuid ?
                            <IconButton sx={{ padding: '3px' }} disabled={editingOrder} onClick={() => handleEditClick(order)}>
                              <Tooltip title="編輯" placement="top">
                                <DriveFileRenameOutline />
                              </Tooltip>
                            </IconButton>
                            :
                            <>
                              <IconButton sx={{ padding: '3px' }} onClick={() => handleSaveClick()}>
                                <Tooltip title="保存" placement="top">
                                  <SaveAs htmlColor='#4476ff' />
                                </Tooltip>
                              </IconButton>
                              <IconButton sx={{ padding: '3px' }} onClick={() => handleCancelClick()}>
                                <Tooltip title="取消" placement="top">
                                  <DoDisturbOn htmlColor='#878ca7' />
                                </Tooltip>
                              </IconButton>
                            </>
                          }
                        </Typography>
                      </Box>

                      <Collapse in={openRow === order.uuid} timeout="auto" unmountOnExit>
                        <Box 
                          className={styles['collapse-box']}
                          sx={{
                            backgroundColor: openRow === order.uuid ? 
                              order.order_status === '未付款' ? '#f1efef' : 
                              order.order_status === '作業金已付' ? '#f6fbf2' : 
                              order.order_status === '尾款已付' ? '#faeeda' : 
                              order.order_status === '全額付款' ? '#dde7fc' : 
                              order.order_status === '取消' ? '#fae7e6' : 
                              '#FFFFFF' 
                            : 
                              '#FFFFFF',
                          }}
                        >

                          <Box
                            className={styles['collapse-content-box']}
                            sx={{
                              maxWidth: `${tableWidth}px`,
                              boxShadow: openRow === order.uuid ?
                                order.order_status === '未付款' ? 
                                  'inset 0 -10px 10px -10px #e5e3e3, inset 10px 0 10px -10px #e5e3e3, inset -10px 0 10px -10px #e5e3e3' :
                                order.order_status === '作業金已付' ?
                                  'inset 0 -10px 10px -10px #e0f2d5, inset 10px 0 10px -10px #e0f2d5, inset -10px 0 10px -10px #e0f2d5' :
                                order.order_status === '尾款已付' ?
                                  'inset 0 -10px 10px -10px #f4dbb4, inset 10px 0 10px -10px #f4dbb4, inset -10px 0 10px -10px #f4dbb4' :
                                order.order_status === '全額付款' ?
                                  'inset 0 -10px 10px -10px #c3d6fa, inset 10px 0 10px -10px #c3d6fa, inset -10px 0 10px -10px #c3d6fa' :
                                order.order_status === '取消' ?
                                  'inset 0 -10px 10px -10px #f6d3d2, inset 10px 0 10px -10px #f6d3d2, inset -10px 0 10px -10px #f6d3d2' :
                                'inset 0 -10px 10px -10px #FFFFFF, inset 10px 0 10px -10px #FFFFFF, inset -10px 0 10px -10px #FFFFFF'
                              :
                                'none',
                            }}
                          >

                            <Box className={styles['collapse-content-row']}>

                              <Divider textAlign="left" sx={{ width: '97%', fontSize: '20px', fontWeight: 'bold', margin: '5px 0' }}>入款資訊</Divider>

                              {order.depositslips && order.depositslips.map((depositslip, index) => (
                                <Box key={depositslip.id} className={styles['item-box']}>
                                  
                                  <Box className={styles['item-row']}>

                                    {depositslip.accounting_audit && depositslip.manager_audit ? (
                                      <Tooltip title="已審核" placement="top" color='success' style={{ position: 'absolute', top: -12, left: -12 }}>
                                        <Verified />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="未審核" placement="top" color='warning' style={{ position: 'absolute', top: -12, left: -12 }}>
                                        <NewReleases />
                                      </Tooltip>
                                    )}

                                    <TextField
                                      label="款項"
                                      defaultValue={depositslip.deposit_item}
                                      variant='standard'
                                      size='small'
                                      focused={false}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        '& .MuiInput-input': {
                                          paddingBottom: '3px',
                                        },
                                      }}
                                    />

                                    <TextField
                                      label="總計金額"
                                      defaultValue={
                                        depositslip.itemdescriptions.reduce((sum, item) => {
                                          const price = parseFloat(item.total_price);
                                          return sum + (isNaN(price) ? 0 : price);
                                        }, 0)
                                        .toFixed(2)
                                        .replace(/\.00$/, '')
                                      }
                                      variant='standard'
                                      type='number'
                                      size='small'
                                      focused={false}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        '& .MuiInput-input': {
                                          paddingBottom: '3px',
                                        },
                                      }}
                                    />

                                    <TextField
                                      label="入款方式"
                                      defaultValue={depositslip.deposit_method}
                                      variant='standard'
                                      size='small'
                                      focused={false}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        '& .MuiInput-input': {
                                          paddingBottom: '3px',
                                        },
                                      }}
                                    />

                                    <TextField
                                      label="入款資訊"
                                      defaultValue={depositslip.deposit_info}
                                      variant='standard'
                                      size='small'
                                      focused={false}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        '& .MuiInput-input': {
                                          paddingBottom: '3px',
                                        },
                                      }}
                                    />

                                    <TextField
                                      label="支票到期日"
                                      defaultValue={depositslip.check_due_date}
                                      variant='standard'
                                      type='date'
                                      size='small'
                                      focused={false}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        minWidth: '110px',
                                        '& .MuiInput-input': {
                                          paddingBottom: '3px',
                                        },
                                      }}
                                    />

                                    <TextField
                                      label="入款日期"
                                      defaultValue={depositslip.deposit_date}
                                      variant='standard'
                                      type='date'
                                      size='small'
                                      focused={false}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        minWidth: '110px',
                                        '& .MuiInput-input': {
                                          paddingBottom: '3px',
                                        },
                                      }}
                                    />

                                  </Box>

                                  <Box className={styles['item-row']}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          error={!!errors.depositslips && !!errors.depositslips[index] && !!errors.depositslips[index].accounting_audit}
                                          helperText={errors.depositslips && errors.depositslips[index] && errors.depositslips[index].accounting_audit}
                                          checked={editingOrder === order.uuid ? editedOrder.depositslips[index].accounting_audit : depositslip.accounting_audit}
                                          onChange={(e) => handleInputChange('depositslips.accounting_audit', e.target.checked, index)}
                                          color="info"
                                          disabled={editingOrder !== order.uuid || (!userGroups.includes('管理員') && !userGroups.includes('會計人員'))}
                                          sx={{
                                            top: '9px',
                                            padding: 0,
                                            margin: '10px',
                                            '&.Mui-disabled': {
                                              color: depositslip.accounting_audit ? 'info.main' : 'rgba(0, 0, 0, 0.26)',
                                            },
                                            '& .MuiSvgIcon-root': {
                                              color: editingOrder !== order.uuid || (!userGroups.includes('管理員') && !userGroups.includes('會計人員'))
                                                ? (depositslip.accounting_audit ? 'info.main' : 'rgba(0, 0, 0, 0.26)') 
                                                : 'inherit',
                                            },
                                          }}
                                        />
                                      }
                                      label="會計審核"
                                      labelPlacement='top'
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        '& .MuiFormControlLabel-label': {
                                          fontSize: '12px',
                                          fontWeight: '400',
                                          color: 'rgba(0, 0, 0, 0.6)',
                                          position: 'absolute',
                                          top: '10px',
                                        },
                                        '& .MuiFormControlLabel-label.Mui-disabled': {
                                          color: 'rgba(0, 0, 0, 0.6)',
                                        },
                                      }}
                                    />

                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          error={!!errors.depositslips && !!errors.depositslips[index] && !!errors.depositslips[index].manager_audit}
                                          helperText={errors.depositslips && errors.depositslips[index] && errors.depositslips[index].manager_audit}
                                          checked={editingOrder === order.uuid ? editedOrder.depositslips[index].manager_audit : depositslip.manager_audit}
                                          onChange={(e) => handleInputChange('depositslips.manager_audit', e.target.checked, index)}
                                          color="info"
                                          disabled={editingOrder !== order.uuid || !userGroups.includes('管理員')}
                                          sx={{
                                            top: '9px',
                                            padding: 0,
                                            margin: '10px',
                                            '&.Mui-disabled': {
                                              color: depositslip.manager_audit ? 'info.main' : 'rgba(0, 0, 0, 0.26)',
                                            },
                                            '& .MuiSvgIcon-root': {
                                              color: editingOrder !== order.uuid || !userGroups.includes('管理員')
                                                ? (depositslip.manager_audit ? 'info.main' : 'rgba(0, 0, 0, 0.26)')
                                                : 'inherit',
                                            },
                                          }}
                                        />
                                      }
                                      label="主管審核"
                                      labelPlacement='top'
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        '& .MuiFormControlLabel-label': {
                                          fontSize: '12px',
                                          fontWeight: '400',
                                          color: 'rgba(0, 0, 0, 0.6)',
                                          position: 'absolute',
                                          top: '10px',
                                        },
                                        '& .MuiFormControlLabel-label.Mui-disabled': {
                                          color: 'rgba(0, 0, 0, 0.6)',
                                        },
                                      }}
                                    />
                                  </Box>

                                </Box>
                              ))}

                              {order.depositslips && order.depositslips.length === 0 && (
                                <Box className={styles['item-box']}>
                                  <Box className={styles['item-no-data']}>
                                    <NoSearchImg style={{ width: '100px'}} />
                                    目前沒有任何入款資訊
                                  </Box>
                                </Box>
                              )}

                              <Divider textAlign="left" sx={{ width: '97%', fontSize: '20px', fontWeight: 'bold', margin: '5px 0' }}>代墊款、退款資訊</Divider>
                              
                              {order.advancepayments && order.advancepayments.map((advancepayment, index) => (
                                <Box key={advancepayment.id} className={styles['item-box']}>

                                  <Box className={styles['item-row']}>

                                    {advancepayment.accounting_audit && advancepayment.manager_audit ? (
                                      <Tooltip title="已審核" placement="top" color='success' style={{ position: 'absolute', top: -12, left: -12 }}>
                                        <Verified />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="未審核" placement="top" color='warning' style={{ position: 'absolute', top: -12, left: -12 }}>
                                        <NewReleases />
                                      </Tooltip>
                                    )}

                                    <TextField
                                      label="代墊(退)款"
                                      defaultValue={advancepayment.advance_method}
                                      variant='standard'
                                      size='small'
                                      focused={false}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        '& .MuiInput-input': {
                                          paddingBottom: '3px',
                                        },
                                      }}
                                    />

                                    <TextField
                                      label="項目"
                                      defaultValue={advancepayment.advance_item}
                                      variant='standard'
                                      size='small'
                                      focused={false}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        '& .MuiInput-input': {
                                          paddingBottom: '3px',
                                        },
                                      }}
                                    />

                                    <TextField
                                      label="金額"
                                      defaultValue={parseFloat(advancepayment.advance_amount).toFixed(2).split('.')[1] === '00' ? parseFloat(advancepayment.advance_amount).toFixed(2).split('.')[0] : parseFloat(advancepayment.advance_amount).toFixed(2)}
                                      variant='standard'
                                      type='number'
                                      size='small'
                                      focused={false}
                                      InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start" style={{ marginRight: '5px' }}><span style={{ padding: '1px 0 3px 0' }}>{advancepayment.advance_currency === 'JPY' ? '¥' : 'TWD' ? 'NT$' : ''}</span></InputAdornment>,
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        '& .MuiInput-input': {
                                          paddingBottom: '3px',
                                        },
                                      }}
                                    />

                                    <TextField
                                      label="支付方式"
                                      defaultValue={advancepayment.payment_method}
                                      variant='standard'
                                      size='small'
                                      focused={false}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        '& .MuiInput-input': {
                                          paddingBottom: '3px',
                                        },
                                      }}
                                    />

                                    <TextField
                                      label="支付日期"
                                      defaultValue={advancepayment.payment_date}
                                      variant='standard'
                                      type='date'
                                      size='small'
                                      focused={false}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        minWidth: '110px',
                                        '& .MuiInput-input': {
                                          paddingBottom: '3px',
                                        },
                                      }}
                                    />

                                  </Box>

                                  <Box className={styles['item-row']}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          error={!!errors.advancepayments && !!errors.advancepayments[index] && !!errors.advancepayments[index].accounting_audit}
                                          helperText={errors.advancepayments && errors.advancepayments[index] && errors.advancepayments[index].accounting_audit}
                                          checked={editingOrder === order.uuid ? editedOrder.advancepayments[index].accounting_audit : advancepayment.accounting_audit}
                                          onChange={(e) => handleInputChange('advancepayments.accounting_audit', e.target.checked, index)}
                                          color="info"
                                          disabled={editingOrder !== order.uuid || (!userGroups.includes('管理員') && !userGroups.includes('會計人員'))}
                                          sx={{
                                            top: '9px',
                                            padding: 0,
                                            margin: '10px',
                                            '&.Mui-disabled': {
                                              color: advancepayment.accounting_audit ? 'info.main' : 'rgba(0, 0, 0, 0.26)',
                                            },
                                            '& .MuiSvgIcon-root': {
                                              color: editingOrder !== order.uuid || (!userGroups.includes('管理員') && !userGroups.includes('會計人員'))
                                                ? (advancepayment.accounting_audit ? 'info.main' : 'rgba(0, 0, 0, 0.26)') 
                                                : 'inherit',
                                            },
                                          }}
                                        />
                                      }
                                      label="會計審核"
                                      labelPlacement='top'
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        '& .MuiFormControlLabel-label': {
                                          fontSize: '12px',
                                          fontWeight: '400',
                                          color: 'rgba(0, 0, 0, 0.6)',
                                          position: 'absolute',
                                          top: '10px',
                                        },
                                        '& .MuiFormControlLabel-label.Mui-disabled': {
                                          color: 'rgba(0, 0, 0, 0.6)',
                                        },
                                      }}
                                    />

                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          error={!!errors.advancepayments && !!errors.advancepayments[index] && !!errors.advancepayments[index].manager_audit}
                                          helperText={errors.advancepayments && errors.advancepayments[index] && errors.advancepayments[index].manager_audit}
                                          checked={editingOrder === order.uuid ? editedOrder.advancepayments[index].manager_audit : advancepayment.manager_audit}
                                          onChange={(e) => handleInputChange('advancepayments.manager_audit', e.target.checked, index)}
                                          color="info"
                                          disabled={editingOrder !== order.uuid || !userGroups.includes('管理員')}
                                          sx={{
                                            top: '9px',
                                            padding: 0,
                                            margin: '10px',
                                            '&.Mui-disabled': {
                                              color: advancepayment.manager_audit ? 'info.main' : 'rgba(0, 0, 0, 0.26)',
                                            },
                                            '& .MuiSvgIcon-root': {
                                              color: editingOrder !== order.uuid || !userGroups.includes('管理員')
                                                ? (advancepayment.manager_audit ? 'info.main' : 'rgba(0, 0, 0, 0.26)')
                                                : 'inherit',
                                            },
                                          }}
                                        />
                                      }
                                      label="主管審核"
                                      labelPlacement='top'
                                      className={styles['collapse-textfield']}
                                      sx={{
                                        '& .MuiFormControlLabel-label': {
                                          fontSize: '12px',
                                          fontWeight: '400',
                                          color: 'rgba(0, 0, 0, 0.6)',
                                          position: 'absolute',
                                          top: '10px',
                                        },
                                        '& .MuiFormControlLabel-label.Mui-disabled': {
                                          color: 'rgba(0, 0, 0, 0.6)',
                                        },
                                      }}
                                    />
                                  </Box>

                                </Box>
                              ))}

                              {order.advancepayments && order.advancepayments.length === 0 && (
                                <Box className={styles['item-box']}>
                                  <Box className={styles['item-no-data']}>
                                    <NoSearchImg style={{ width: '100px'}} />
                                    目前沒有任何代墊款、退款資訊
                                  </Box>
                                </Box>
                              )}
                              
                            </Box>
                            
                          </Box>
                        </Box>
                      </Collapse>
                    </div>
                  ))
                }

                {!loading && filteredOrders.length === 0 && orders.length > 0 &&
                  <Box className={styles['no-data']}>
                    <NoFilterImg style={{ width: '300px', height: '300px' }} />
                    目前沒有任何符合篩選條件的訂單
                  </Box>
                }

                {!loading && orders.length === 0 &&
                  <Box className={styles['no-data']}>
                    <NoDataImg style={{ width: '300px', height: '300px' }} />
                    目前沒有任何訂單數據
                  </Box>
                }

                {loading &&
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '288px' }}>
                    <CircularProgress />
                  </Box>
                }

              </Box>
            </Box>

          </CardContent>
        </Card>
      </Box>

    </Container>
  );
}

export default AccountingTable;
