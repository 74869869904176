import React, { useState, useEffect } from 'react';
import { useUnsavedChanges } from '../../../contexts/UnsavedChangesContext';
import UserGroupContext from '../../../contexts/UserGroupContext';
import api from '../../../config/axiosConfig';
import { Alert, Box, Button, Chip, CircularProgress, Divider, Grid, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { DoDisturbOn, Download, EditNote, SaveAs } from '@mui/icons-material';
import { ReactComponent as NoDataImg } from '../../../pages/img/NoData.svg';
import { ReactComponent as SearchingImg } from '../img/searching.svg';
import styles from './css/AppointmentTab.module.css';

const AppointmentTab = ({ uuid }) => {
    const userGroups = React.useContext(UserGroupContext);
    const [data, setData] = useState(null);
    const [tab, setTab] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('initial');
    const [errors, setErrors] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [lastClickTime, setLastClickTime] = useState(0);
    const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = '';
          }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        
    }, [hasUnsavedChanges]);

    useEffect(() => {
        setHasUnsavedChanges(false);

        const fetchData = async () => {
            setLoading(true);
            try {

                const dataResponse = await api.get(`/japan/api/Order/${uuid}/`);
                setData(dataResponse.data);
                
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setErrors(error.response.data);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [uuid]);

    useEffect(() => {
        let timer;
        if (status === 'save-success' || status === 'save-error' || status === 'save-nochange') {
            timer = setTimeout(() => {
                if (status === 'save-success' || status === 'save-error' || status === 'save-nochange') {
                    setStatus('initial');
                }
            }, 6500);
        }
    
        return () => clearTimeout(timer);
    }, [status]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleExportRequest = (urlPath, fileName) => {
        const now = Date.now();
        if (now - lastClickTime < 3000) {
            return;
        }
    
        setLastClickTime(now);
    
        handleMenuClose();
        // setIsLoading(true);
    
        const uniqueUrl = `${urlPath}?t=${now}`;
        
        api.get(uniqueUrl, { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
        .catch(error => {
            console.error('下載發生錯誤:', error);
        })
        .finally(() => {
            // setIsLoading(false);
        });
    }

    const handleFieldChange = (arrayName, fieldName, fieldValue, idOrIdentifier, isParentNew = false, nestedArrayName = null, parentId = null, isNestedNew = false) => {
        setEditData((prevState) => {
            let currentArray = prevState?.[arrayName] ? [...prevState[arrayName]] : [];
    
            if (parentId && nestedArrayName) {
                // 查找父級項目，無論父級是否是新的
                let parentIndex = currentArray.findIndex(item => item.id === parentId || item.identifier === parentId);
    
                // 如果父級不存在，創建父級數據
                if (parentIndex === -1) {
                    const newParentItem = {
                        [nestedArrayName]: [],
                        ...(isParentNew ? { identifier: parentId } : { id: parentId })
                    };
                    currentArray.push(newParentItem);
                    parentIndex = currentArray.length - 1;
                }
    
                // 獲取父級項目並找到其嵌套數組
                const parentItem = { ...currentArray[parentIndex] };
                let nestedArray = parentItem[nestedArrayName] ? [...parentItem[nestedArrayName]] : [];
    
                // 根據 isNestedNew 和 identifier/id 查找嵌套數據
                const nestedItemIndex = isNestedNew
                    ? nestedArray.findIndex(item => item.identifier === idOrIdentifier)
                    : nestedArray.findIndex(item => item.id === idOrIdentifier);
    
                if (nestedItemIndex !== -1) {
                    nestedArray[nestedItemIndex] = {
                        ...nestedArray[nestedItemIndex],
                        [fieldName]: fieldValue
                    };
                } else {
                    const newNestedItem = {
                        [fieldName]: fieldValue
                    };
                    if (isNestedNew) {
                        newNestedItem.identifier = idOrIdentifier;
                    } else {
                        newNestedItem.id = idOrIdentifier;
                    }
                    nestedArray.push(newNestedItem);
                }
    
                parentItem[nestedArrayName] = nestedArray;
                currentArray[parentIndex] = parentItem;
    
            } else {
                // 操作父級數據（非嵌套）
                const itemIndex = currentArray.findIndex(item => item.id === idOrIdentifier || item.identifier === idOrIdentifier);
    
                if (itemIndex !== -1) {
                    currentArray[itemIndex] = {
                        ...currentArray[itemIndex],
                        [fieldName]: fieldValue
                    };
                } else {
                    const newItem = {
                        [fieldName]: fieldValue
                    };
                    if (isParentNew) {
                        newItem.identifier = idOrIdentifier;
                    } else {
                        newItem.id = idOrIdentifier;
                    }
                    currentArray.push(newItem);
                }
            }
    
            return {
                ...prevState,
                [arrayName]: currentArray
            };
        });
    };

    const handleEditClick = () => {
        setErrors(null);
        setEditMode(true);
        setHasUnsavedChanges(true);
    };

    const handleCancelClick = () => {
        setEditData(null);
        setErrors(null);
        setEditMode(false);
        setHasUnsavedChanges(false);
    };

    const handleSaveClick = async () => {
        const now = Date.now();
        if (now - lastClickTime < 3000) {
            console.log('請勿連續點擊保存按鈕');
            return;
        }

        setLastClickTime(now);
        
        try {

            setErrors(null);
            
            // const custom_errors = {};
            // if (Object.keys(custom_errors).length > 0) {
            //     setErrors(custom_errors);
            //     setStatus('save-error');
            //     return;
            // }

            if (!editData) {
                setErrors(null);
                setEditMode(false);
                setEditData(null);
                setHasUnsavedChanges(false);
                setStatus('save-nochange');
                return;
            }

            const response = await api.patch(`/japan/api/Order/${uuid}/`, editData);
            setErrors(null);
            setData(response.data);
            setEditData(null);
            setEditMode(false);
            setHasUnsavedChanges(false);
            setStatus('save-success');

        } catch (error) {

            console.error('Error saving data:', error);
            setStatus('save-error');
            setErrors(error.response.data);

        }
    };

    return (
        <Box className={styles['main-box']}>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                <Box className={styles['print-box']}>
                    <Button
                        className={styles['print-button']}
                        sx={{
                            '& .MuiButton-startIcon': {
                                margin: 0,
                                '&>*:nth-of-type(1)': {
                                    fontSize: '18px',
                                }
                            },
                        }}
                        disabled={editMode}
                        startIcon={<Download />}
                        onClick={handleMenuClick}
                    >
                        下載導出
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                    >
                        {(data?.full_amount || data?.operation_fee) &&
                            <MenuItem onClick={() => handleExportRequest(`/japan/export_order_booking/${uuid}/`, `${data?.group_number} - 預約書.xlsx`)}>預約書</MenuItem>
                        }
                    </Menu>
                </Box>

                <Box className={styles['category-box']}>
                    <Button className={tab === 0 ? styles['category-button-selected'] : styles['category-button']} onClick={() => setTab(0)}>
                        訂單資料
                    </Button>
                    <Button className={tab === 1 ? styles['category-button-selected'] : styles['category-button']} onClick={() => setTab(1)}>
                        行程資訊
                    </Button>
                </Box>
            </Box>

            <Box className={styles['content-box']}>

                {tab === 0 && data && (
                    <Box className={styles['tab-main-box']}>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="基本信息" />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={3}>
                                <TextField
                                    label="客戶名稱"
                                    variant="standard"
                                    value={data?.customer_name || data?.peers_sales?.peers_sales_name}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="客戶電話"
                                    variant="standard"
                                    value={data?.customer_phone || data?.peers_sales?.peers_sales_phone}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="通訊軟體"
                                    variant="standard"
                                    value={data?.communication_app || data?.peers_sales?.peers_sales_communication_app}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="通訊軟體ID"
                                    variant="standard"
                                    value={data?.communication_id || data?.peers_sales?.peers_sales_communication_id}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="電子郵件/Email"
                                    variant="standard"
                                    value={data?.customer_email || data?.peers_sales?.peers_sales_email}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="訂單資訊" />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={4}>
                                <TextField
                                    label="團號"
                                    variant="standard"
                                    value={data?.group_number}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    error={errors?.custom_group_number}
                                    helperText={errors?.custom_group_number}
                                    label="自訂團號"
                                    variant="standard"
                                    value={editData?.custom_group_number === '' ? '' : editData?.custom_group_number || data?.custom_group_number}
                                    size='small'
                                    fullWidth
                                    focused={editMode && (userGroups.includes("日方人員") || userGroups.includes("管理員"))}
                                    InputProps={{
                                        readOnly: !editMode || !(userGroups.includes("日方人員") || userGroups.includes("管理員")),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEditData({ ...editData, custom_group_number: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="承辦業務"
                                    variant="standard"
                                    value={data?.sales_rep?.profile?.nickname || data?.sales_rep?.username}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="旅遊人數"
                                    variant="standard"
                                    value={data?.num_travelers}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="出發日期"
                                    variant="standard"
                                    value={data?.travel_start_date}
                                    type='date'
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="返回日期"
                                    variant="standard"
                                    value={data?.travel_end_date}
                                    type='date'
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="包車內容"
                                    variant="standard"
                                    value={data?.service_type}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="使用車型"
                                    variant="standard"
                                    value={data?.vehicle_type?.vehicle_name}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="車輛數量"
                                    variant="standard"
                                    value={data?.num_vehicles}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="地區"
                                    variant="standard"
                                    value={data?.area}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="司機住宿天數"
                                    variant="standard"
                                    value={data?.include_driver_accommodation}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="兒童座椅張數"
                                    variant="standard"
                                    value={data?.include_babyseat}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    error={errors?.order_booking_notes}
                                    helperText={errors?.order_booking_notes}
                                    label="備註"
                                    variant="standard"
                                    value={editData?.order_booking_notes === '' ? '' : editData?.order_booking_notes || data?.order_booking_notes}
                                    multiline
                                    fullWidth
                                    focused={editMode && !(userGroups.includes("日方人員"))}
                                    InputProps={{
                                        readOnly: !editMode || (userGroups.includes("日方人員")),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setEditData({ ...editData, order_booking_notes: e.target.value })}
                                />
                            </Grid>
                        </Grid>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label="額外資訊" />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            <Grid item xs={6}>
                                <TextField
                                    label="抵達航班編號"
                                    variant="standard"
                                    value={data?.arrival_flight_number}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="離開航班編號"
                                    variant="standard"
                                    value={data?.departure_flight_number}
                                    size='small'
                                    fullWidth
                                    focused={false}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label={"注意事項 / " + (data?.warning_type?.warning_type)} />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                            {data?.warning_type?.warnings.map((warning, index) => (
                                <Grid item xs={12} key={index}>
                                    <TextField
                                        label={"事項" + (index + 1)}
                                        variant="standard"
                                        value={warning.warning_note}
                                        multiline
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            ))}
                            {data?.warning_type?.warnings.length === 0 && (
                                <Grid item xs={12}>
                                    <Typography sx={{ fontSize: '15px', fontWeight: 'bold', color: '#fca5a5', textAlign: 'center' }}>注意事項 "{data?.warning_type?.warning_type}" 類別中，未包含任何注意事項內容</Typography>
                                </Grid>
                            )}
                        </Grid>

                        <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                            <Chip className={styles['chip-text']} label={"取消政策 / " + (data?.policy_type?.policy_type)} />
                        </Divider>
                        <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px 10px 16px' }}>
                            {data?.policy_type?.policies.map((policy, index) => (
                                <Grid item xs={12} key={index}>
                                    <TextField
                                        label={"政策" + (index + 1)}
                                        variant="standard"
                                        value={policy.policy_note}
                                        multiline
                                        size='small'
                                        fullWidth
                                        focused={false}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            ))}
                            {data?.policy_type?.policies.length === 0 && (
                                <Grid item xs={12}>
                                    <Typography sx={{ fontSize: '15px', fontWeight: 'bold', color: '#fca5a5', textAlign: 'center' }}>取消政策 "{data?.policy_type?.policy_type}" 類別中，未包含任何政策內容</Typography>
                                </Grid>
                            )}
                        </Grid>

                        <Box />
                    
                    </Box>
                )}

                {tab === 1 && data && (
                    <Box className={styles['tab-main-box']}>

                        {data?.itineraries.map((itinerary, index) => (
                            <div key={itinerary.id}>
                                <Divider variant='middle' textAlign='left' sx={{ marginTop: '10px' }}>
                                    <Chip className={styles['itineraries-chip-text']} color={itinerary.external_company ? 'success' : 'error'} label={"行程安排 / " + (editMode ? "編輯中. . ." : itinerary.external_company || "未指定承接公司")}/>
                                </Divider>
                                <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                    {editMode && (
                                        <Grid item xs={12}>
                                            <TextField
                                                error={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.external_company}
                                                helperText={errors?.itineraries?.find(error => error.id === String(itinerary.id))?.external_company}
                                                label="承接公司"
                                                variant="standard"
                                                value={
                                                    editData?.itineraries?.find(it => it.id === itinerary.id)?.external_company === ''
                                                        ? ''
                                                        : editData?.itineraries?.find(it => it.id === itinerary.id)?.external_company || itinerary?.external_company
                                                }
                                                size='small'
                                                fullWidth
                                                focused={true}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFieldChange("itineraries", "external_company", e.target.value, itinerary.id)}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={3}>
                                        <TextField
                                            label="日期"
                                            variant="standard"
                                            value={itinerary.itinerary_date}
                                            type='date'
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="接待時間"
                                            variant="standard"
                                            value={itinerary.reception_hours}
                                            type='time'
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="訂房資訊"
                                            variant="standard"
                                            value={itinerary.room_reservation}
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="服務內容"
                                            variant="standard"
                                            value={itinerary.service_info}
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="行程內容"
                                            variant="standard"
                                            value={itinerary.activity}
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: { style: { textAlign: 'center' }}
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="飯店資訊"
                                            variant="standard"
                                            value={itinerary.itinerary_hotel}
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                                inputProps: { style: { textAlign: 'center' }}
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="備註"
                                            variant="standard"
                                            value={itinerary.itinerary_notes}
                                            multiline
                                            size='small'
                                            fullWidth
                                            focused={false}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        ))}

                        {data?.itineraries.length === 0 && (
                            <Grid container spacing={1} sx={{ width: 'auto', margin: '0 16px' }}>
                                <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <SearchingImg style={{ width: '300px', height: '300px' }} />
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#9E9E9E' }}>此訂單中，沒有任何行程資訊</Typography>
                                </Grid>
                            </Grid>
                        )}

                        <Box />

                    </Box>
                )}

                {!data && (
                    <Box sx={{ fontSize: '15px', fontWeight: 'bold', color: '#fca5a5', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        {loading ? (
                            <CircularProgress color="info" />
                        ) : (
                            <>
                                <NoDataImg style={{ width: '300px', height: '300px' }} />
                                無法取得訂單資料或發生錯誤: {errors?.detail}
                            </>
                        )}
                    </Box>
                )}

            </Box>

            <Box sx={{ position: 'absolute', left: '50%', bottom: '25px', transform: 'translate(-50%, -50%)' }}>
                {status === 'save-nochange' && (
                    <Alert className={styles['snackbar']} severity="info" onClose={() => setStatus('initial')}>
                        未進行任何操作，無需保存
                    </Alert>
                )}
                {status === 'save-success' && (
                    <Alert className={styles['snackbar']} severity="success" onClose={() => setStatus('initial')}>
                        成功保存編輯後的訂單資料
                    </Alert>
                )}
                {status === 'save-error' && (
                    <Alert className={styles['snackbar']} severity="error" onClose={() => setStatus('initial')}>
                        無法保存，請檢查資料是否正確
                    </Alert>
                )}
            </Box>

            <Box className={styles['action-box']}>
                {editMode ? (
                    <>
                        <Button className={styles['cancel-button']} onClick={handleCancelClick}>
                            <DoDisturbOn sx={{ width: '22px', height: '22px' }} />
                            取消
                        </Button>
                        <Button className={styles['save-button']} onClick={handleSaveClick}>
                            <SaveAs sx={{ width: '22px', height: '22px' }} />
                            保存
                        </Button>
                    </>
                ) : (
                    <Button className={styles['edit-button']} onClick={handleEditClick}>
                        <EditNote sx={{ width: '22px', height: '22px' }} />
                        編輯
                    </Button>
                )}
            </Box>
        
        </Box>
    );
}

export default AppointmentTab;