import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation} from 'react-router-dom';
import { useUnsavedChanges } from '../../contexts/UnsavedChangesContext';
import api from '../../config/axiosConfig';
import UserGroupContext from '../../contexts/UserGroupContext';
import { CircularProgress, Box, Button, Container, Typography } from '@mui/material';
import { LibraryBooks, Style, SpeakerNotes, Receipt, ReceiptLong, LocalPrintshop, Calculate } from '@mui/icons-material';
import { ReactComponent as NotFoundImg } from '../../pages/img/NotFoundImg.svg';
import styles from './css/OrderInformation.module.css';
import RequestTab from './OrderInformationTabs/RequestTab';
import AppointmentTab from './OrderInformationTabs/AppointmentTab';
import CommunicationsTab from './OrderInformationTabs/CommunicationsTab';
import AdvanceTab from './OrderInformationTabs/AdvanceTab';
import DepositTab from './OrderInformationTabs/DepositTab';
import LocalgroupTab from './OrderInformationTabs/LocalgroupTab';
import CostcalculationTab from './OrderInformationTabs/CostcalculationTab';

function OrderInformation(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const userGroups = React.useContext(UserGroupContext);
  const { uuid } = useParams();
  const [groupNumber, setGroupNumber] = useState(null);
  const [options, setOptions] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [communicationType, setCommunicationType] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadStatus, setLoadStatus] = useState('null');
  const { hasUnsavedChanges, setHasUnsavedChanges } = useUnsavedChanges();

  useEffect(() => {
      setHasUnsavedChanges(false);
      setLoading(true);

      const fetchGroupNumber = async () => {
          try {
              const response = await api.get(`/japan/api/Order/${uuid}/?from=Information`);
              setGroupNumber(response.data.group_number);
              const optionsResponse = await api.get(`/japan/api/edit-order-options/`);
              setOptions(optionsResponse.data);

              setLoading(false);
          } catch (error) {
              console.error('Error fetching group number:', error);
              setLoadStatus('error');
              setLoading(false);
          }
      };

      fetchGroupNumber();

      // eslint-disable-next-line
  }, [uuid]);

  useEffect(() => {
      if (groupNumber) {
          document.title = `訂單完整資訊 - ${groupNumber}`;
      }
  }, [groupNumber]);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    const [baseHash, subHash] = hash.split('=');
    switch (baseHash) {
      case 'request':
        if (!userGroups.includes('日方人員')) {
          setTabValue(0);
        } else {
          setTabValue(1);
          navigate('#appointment');
        }
        break;
      case 'appointment':
        setTabValue(1);
        break;
      case 'communications':
        if (userGroups.includes('日方人員') && subHash !== 'external') {
      setTabValue(2);
      setCommunicationType('external');
      navigate('#communications=external');
    } else {
      setTabValue(2);
      setCommunicationType(subHash || 'external');
    }
        break;
      case 'advance':
      case 'deposit':
      case 'localgroup':
      case 'costcalculation':
        if (!userGroups.includes('日方人員')) {
          setTabValue(baseHash === 'advance' ? 3 : baseHash === 'deposit' ? 4 : baseHash === 'localgroup' ? 5 : 6);
        } else {
          setTabValue(1);
          navigate('#appointment');
        }
        break;
      default:
        if (userGroups.includes('日方人員')) {
          setTabValue(1); // 日方人員默認導航至預約書
          navigate('#appointment');
        } else {
          setTabValue(0);
          navigate('#request');
        }
    }
  }, [location.hash, userGroups, navigate]);

  useEffect(() => {
    if (userGroups.includes('日方人員') && (tabValue === 3 || tabValue === 4 || tabValue === 5 || tabValue === 6)) {
      navigate('#appointment'); // 強制導航日方人員至預約書頁面
    }
  }, [tabValue, userGroups, navigate]);

  const handleTabChange = (newValue) => {
    if (hasUnsavedChanges) return;
    if (userGroups.includes('日方人員') && (newValue === 3 || newValue === 4 || newValue === 5 || newValue === 6)) {
      return;
    }
    setTabValue(newValue);
    let hash = '';
    switch (newValue) {
      case 0:
        hash = 'request';
        break;
      case 1:
        hash = 'appointment';
        break;
      case 2:
        hash = `communications=${communicationType}`;
        break;
      case 3:
        hash = 'advance';
        break;
      case 4:
        hash = 'deposit';
        break;
      case 5:
        hash = 'localgroup';
        break;
      case 6:
        hash = 'costcalculation';
        break;
      default:
        hash = 'request';
    }
    navigate(`#${hash}`);
  };

  const renderTabContent = () => {
    switch (tabValue) {
        case 0:
            return (<RequestTab uuid={uuid} options={options} />);
        case 1:
            return (<AppointmentTab uuid={uuid} />);
        case 2:
            return (<CommunicationsTab uuid={uuid} options={options} currentUser={props.CurrentUser} type={communicationType} />);
        case 3:
            return (<AdvanceTab uuid={uuid} options={options} />);
        case 4:
            return (<DepositTab uuid={uuid} options={options} />);
        case 5:
            return (<LocalgroupTab uuid={uuid} options={options} />);
        case 6:
            return (<CostcalculationTab uuid={uuid} options={options} />);
        default:
            return null;
    }
  };

  if (loading) {
    return (
      <Container maxWidth={false} className={styles['main-container']}>
        <Box sx={{ fontSize: '15px', fontWeight: 'bold', color: '#fca5a5', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (loadStatus === 'error') {
    return (
      <Box sx={{ height: 'calc(100vh - 64px - 40px - 48px)', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <NotFoundImg style={{ maxWidth: '300px', marginBottom: '20px' }} />
          <Typography color="GrayText" sx={{ fontSize: '40px', fontWeight: 'bold'}}>
            頁 面 不 存 在
          </Typography>
      </Box>
    );
  }

  return (
    groupNumber && (
      <Container maxWidth={false} className={styles['main-container']}>
          <Typography className={styles['page-title']}>
              {hasUnsavedChanges ? "正在編輯此筆訂單資訊" : groupNumber + "訂單完整資訊"}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '5px' }}>
              <Box className={styles['tab-box']}>
                  {!userGroups.includes('日方人員') && (
                    <Button className={tabValue === 0 ? styles['tabs-button-selected'] : styles['tabs-button']} disableRipple disabled={hasUnsavedChanges} onClick={() => handleTabChange(0)}>
                        <LibraryBooks className={styles['tabs-button-icon']} />
                        請求書
                    </Button>
                  )}
                  <Button className={tabValue === 1 ? styles['tabs-button-selected'] : styles['tabs-button']} disableRipple disabled={hasUnsavedChanges} onClick={() => handleTabChange(1)}>
                      <Style className={styles['tabs-button-icon']} />
                      預約書
                  </Button>
                  <Button className={tabValue === 2 ? styles['tabs-button-selected'] : styles['tabs-button']} disableRipple disabled={hasUnsavedChanges} onClick={() => handleTabChange(2)}>
                      <SpeakerNotes className={styles['tabs-button-icon']} />
                      聯繫記錄
                  </Button>
                  {!userGroups.includes('日方人員') && (
                    <>
                      <Button className={tabValue === 3 ? styles['tabs-button-selected'] : styles['tabs-button']} disableRipple disabled={hasUnsavedChanges} onClick={() => handleTabChange(3)}>
                          <Receipt className={styles['tabs-button-icon']} />
                          代墊/退款單
                      </Button>
                      <Button className={tabValue === 4 ? styles['tabs-button-selected'] : styles['tabs-button']} disableRipple disabled={hasUnsavedChanges} onClick={() => handleTabChange(4)}>
                          <ReceiptLong className={styles['tabs-button-icon']} />
                          入款單
                      </Button>
                      <Button className={tabValue === 5 ? styles['tabs-button-selected'] : styles['tabs-button']} disableRipple disabled={hasUnsavedChanges} onClick={() => handleTabChange(5)}>
                          <LocalPrintshop className={styles['tabs-button-icon']} />
                          當地團體明細
                      </Button>
                      <Button className={tabValue === 6 ? styles['tabs-button-selected'] : styles['tabs-button']} disableRipple disabled={hasUnsavedChanges} onClick={() => handleTabChange(6)}>
                          <Calculate className={styles['tabs-button-icon']} />
                          成本計算
                      </Button>
                    </>
                  )}
              </Box>
          </Box>
          
          {renderTabContent()}

      </Container>
    )
  );
}

export default OrderInformation;